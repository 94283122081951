$font-stack: "Mabry-Regular", sans-serif;
$font-stack-bold: "MabryPro-Bold", sans-serif;
$font-stack-google: "Work Sans", sans-serif;

$gbm-thin: 100;
$gbm-light: 200;
$gbm-regular: 300;
$gbm-medium: 400;
$gbm-semibold: 500;
$gbm-bold: 600;
$gbm-extrabold: 700;
$gbm-black: 800;
$gbm-heavy: 900;
