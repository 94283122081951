/* stylelint-disable declaration-no-important */
.radio {
  cursor: pointer;

  &--card {
    width: 352px !important;
    height: auto !important;
    max-height: 108px !important;
    outline: 0;
  }
}
