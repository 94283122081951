.cancel-screen {
  position: fixed;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: $white;
  font-family: $font-stack;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:first-child {
      @media (max-width: $xs) {
        width: auto;
        margin: 0 24px;
      }
    }

    img {
      margin-bottom: 24px;
    }

    &-question {
      max-width: 603px;
      font-family: $font-stack-bold;
      font-size: 42px;
      line-height: 1.24;
      text-align: center;

      @media (max-width: $sm) {
        max-width: 544px;
        font-size: 32px;
      }

      @media (max-width: $xs) {
        font-size: 24px;
      }
    }

    &-description {
      max-width: 864px;
      padding-top: 24px;
      font-family: $font-stack-google;
      font-size: 20px;
      font-weight: $gbm-medium;
      line-height: 1.5;
      text-align: center;

      @media (max-width: $sm) {
        max-width: 544px;
        font-size: 16px;
      }

      @media (max-width: $xs) {
        font-size: 16px;
      }
    }

    &-controls {
      margin-top: 40px;

      .button--link {
        border-bottom: 1px solid transparent;
        margin-top: 24px;
      }
    }
  }

  &__button {
    &--cancel {
      border: transparent;
      margin-top: 24px;
      background: transparent;
      color: black;
      cursor: pointer;
      font-size: 16px;
      font-weight: $gbm-bold;
      outline: none;
      text-decoration: none;

      &:focus,
      &:hover {
        border-bottom: 1px dotted $black;
      }
    }

    &--small {
      width: 192px;
      margin-bottom: 24px;
    }
  }
}
