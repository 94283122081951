/* stylelint-disable */
.input {
  display: inherit;

  &__input {
    height: 34px;

    &--big {
      height: 34px !important;
    }

    &--center {
      text-align: center;
    }
  }
}

.input-wrapper {
  margin-top: 42px;

  .input {
    &--currency,
    &--years {
      &::before,
      &::after {
        top: 0;
      }
    }
  }
}

.input--question {
  display: flex;
}
