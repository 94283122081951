/* stylelint-disable declaration-no-important */
@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700&display=swap");
@import '~modern-normalize/modern-normalize.css';
@import "~@gbm/guidelines-2.0/dist/guidelines.min.css";
.back-button {
  z-index: 1;
  width: 100%;
  margin-left: 8px;
  background-color: #FFF;
}
.back-button button {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}
.back-button__icon {
  width: 24px;
  height: 24px;
  background: url("../images/atm-link-icn-back.svg") no-repeat center center transparent;
}
.back-button__label {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.bars {
  display: flex;
}

.bar {
  display: flex;
  width: 4px;
  height: 14px;
  margin-right: 6px;
  background-color: #525252;
}
.bar:last-child {
  margin-right: 0;
}
.bar--active {
  background-color: #FFF;
}

.advisor-button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 768px) {
  .advisor-button {
    margin-top: 48px;
    margin-right: auto;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .advisor-button {
    margin-right: 0;
  }
}
.advisor-button--secondary {
  background-color: #FFF;
  color: #000;
  outline: none;
  -webkit-transition: background-color 120ms ease-in-out;
  -moz-transition: background-color 120ms ease-in-out;
  -o-transition: background-color 120ms ease-in-out;
  transition: background-color 120ms ease-in-out;
}
.advisor-button--secondary:hover, .advisor-button--secondary:focus {
  background-color: white;
}
.advisor-button--secondary-hidden {
  display: none;
}
.advisor-button + .advisor-button--link {
  margin-top: 25px;
}

.button--top {
  margin-top: 48px;
}
.button--wrapper {
  margin-top: 48px;
}
.button--wrapper .advisor-button--secondary {
  display: none;
}
@media (max-width: 576px) {
  .button--wrapper {
    display: flex;
    justify-content: space-between;
    margin: 40px 24px 40px 24px;
  }
  .button--wrapper .advisor-button--secondary {
    display: block;
    margin-top: 8px;
  }
  .button--wrapper-single {
    justify-content: flex-start;
    margin-right: 24px;
  }
  .button--wrapper-single .advisor-button--secondary {
    display: none;
  }
}
.button--link {
  border: transparent;
}
.button--link.link {
  border-bottom: 1px solid transparent;
  background-color: transparent;
  font-size: 16px;
}
.button--link.link-old {
  margin-bottom: 80px;
}

/* stylelint-disable declaration-no-important */
.done-screen {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #FFF;
  color: #000;
  font-family: "Mabry-Regular", sans-serif;
}
.done-screen__container {
  display: flex;
  width: 100%;
  max-width: 672px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: -76px;
  margin-left: 300px;
}
@media (max-width: 992px) {
  .done-screen__container {
    align-items: initial;
    margin-left: 144px;
  }
}
@media (max-width: 768px) {
  .done-screen__container {
    max-width: 480px;
    align-items: initial;
    margin-left: 80px;
  }
}
.done-screen__container-icon {
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .done-screen__container-icon {
    margin-left: 32px;
  }
}
.done-screen__container-icon img {
  width: 87px;
  height: 20px;
}
.done-screen__container-mark {
  margin-bottom: 64px;
  font-size: 20px;
  font-weight: 400;
  line-height: 0.6;
}
@media (max-width: 576px) {
  .done-screen__container-mark {
    margin-left: 32px;
  }
}
.done-screen__container__logo {
  width: 100px;
  padding-bottom: 96px;
}
.done-screen__container__logo img {
  height: 20px;
}
@media (max-width: 576px) {
  .done-screen__container__logo img {
    width: 140px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .done-screen__container__logo {
    width: 90%;
  }
}
@media (max-width: 576px) {
  .done-screen__container__logo {
    padding-bottom: 64px;
  }
}
.done-screen__container h1 {
  margin-top: 0;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .done-screen__container h1 {
    width: 90%;
  }
}
@media (max-width: 576px) {
  .done-screen__container h1 {
    font-size: 28px;
  }
}
.done-screen__container-message {
  padding-bottom: 40px;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .done-screen__container-message {
    width: 90%;
  }
  .done-screen__container-message br {
    display: none;
  }
}
@media (max-width: 576px) {
  .done-screen__container-message {
    font-size: 16px;
    line-height: 1.64;
  }
}
@media (max-width: 768px) {
  .done-screen__container-controls {
    width: 90%;
  }
}
.done-screen__container-controls button {
  width: 192px;
}
.done-screen__close {
  display: flex;
  height: 76px;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .done-screen__close {
    position: absolute;
    top: 21px;
    width: 100%;
    height: auto;
  }
}
.done-screen__close img {
  margin-left: 32px;
}
@media (max-width: 768px) {
  .done-screen__close img {
    margin-left: 32px;
  }
}
@media (max-width: 576px) {
  .done-screen__close img {
    margin-left: 16px;
  }
}
.done-screen__close-button {
  z-index: 1;
  width: 28px;
  height: 28px;
  border: none;
  margin-right: 10px;
  background-color: transparent;
  background-image: url(../images/atm-cta-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
@media (max-width: 576px) {
  .done-screen__close-button--white {
    width: 16px;
    height: 16px;
  }
}
.done-screen__close-button--black {
  display: none;
}
@media (max-width: 576px) {
  .done-screen__close-button--black {
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../images/atm-icn-close-black.svg);
  }
}

header {
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 76px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  transition: all 0.3s ease;
}
header img {
  margin-left: 32px;
}
@media (max-width: 576px) {
  header {
    position: relative;
    max-height: 60px;
    margin: 0;
  }
}

.header__back--hidden {
  display: none;
}

.advisor-header--logo {
  width: 100px;
  margin-left: 32px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 768px) {
  .advisor-header--logo {
    position: absolute;
    left: 32px;
    width: 79px;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .advisor-header--logo {
    left: 24px;
    width: 48px;
  }
}

.help-card {
  display: flex;
  width: 544px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 32px;
  margin-bottom: 20px;
}
.help-card--info {
  background-color: #F0F1FE;
}
.help-card__icon {
  width: 24px;
  height: 24px;
  padding-right: 16px;
}
.help-card__icon--info {
  background: url("../images/atm-icn-bell.svg") no-repeat center center;
}
.help-card__message {
  margin-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.67;
}
.help-card__message strong {
  font-weight: 600;
}

/* stylelint-disable */
.input {
  display: inherit;
}
.input__input {
  height: 34px;
}
.input__input--big {
  height: 34px !important;
}
.input__input--center {
  text-align: center;
}

.input-wrapper {
  margin-top: 42px;
}
.input-wrapper .input--currency::before, .input-wrapper .input--currency::after, .input-wrapper .input--years::before, .input-wrapper .input--years::after {
  top: 0;
}

.input--question {
  display: flex;
}

.container-loader {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-loader .loader {
  margin-bottom: 16px;
}
.container-loader .loader__quote, .container-loader .loader__custom-message {
  max-width: 400px;
  animation: fade-quote 1s ease-in-out;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 1.75;
  opacity: 1;
  text-align: center;
  text-decoration: none;
}
.container-loader .loader__custom-message {
  font-weight: 600;
}
.container-loader--dark {
  background-color: #141414;
}
@keyframes fade-quote {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* stylelint-disable declaration-no-important */
.radio {
  cursor: pointer;
}
.radio--card {
  width: 352px !important;
  height: auto !important;
  max-height: 108px !important;
  outline: 0;
}

.multiple-note {
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: #525252;
  font-size: 14px;
}
.multiple-note img {
  margin-left: 8px;
}

.options {
  display: grid;
  margin-top: 42px;
  grid-auto-rows: auto;
  grid-gap: 8px 32px;
}
.options--multiple {
  margin-top: 32px;
}
.options--column-1 {
  grid-template-columns: repeat(1, 352px);
}
.options--column-2 {
  grid-template-columns: repeat(2, 352px);
}
@media (max-width: 768px) {
  .options--column-2 {
    grid-template-columns: repeat(1, 352px);
  }
}
.options--column-3 {
  grid-template-columns: repeat(3, 160px);
}
.options .radio--bar {
  display: flex;
  width: auto;
  height: auto;
  margin-bottom: 8px;
  outline: 0;
}
@media (max-width: 768px) {
  .options {
    flex-direction: column;
  }
}

.portfolio-chart-cursor--default {
  cursor: default;
}
.portfolio-chart-cursor--pointer {
  cursor: pointer;
}

.advisor-sidebar {
  display: flex;
  width: 48px;
  justify-content: center;
  background-color: #444EE6;
  transition: all 0.2s ease-in;
}
@media (max-width: 768px) {
  .advisor-sidebar {
    position: fixed;
    right: 0;
    width: 48px;
    height: 100vh;
  }
}
@media (max-width: 768px), (max-width: 992px) {
  .advisor-sidebar {
    width: 48px;
  }
}
@media (max-width: 576px) {
  .advisor-sidebar {
    display: none;
  }
}

.slider__label {
  display: flex;
  width: 320px;
  justify-content: flex-end;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 600;
}

[data-reach-slider] {
  width: 320px;
  margin-bottom: 32px;
}

[data-reach-slider-track][data-orientation=horizontal] {
  height: 4px;
}

[data-reach-slider-range] {
  background-color: #444EE6;
}

[data-reach-slider-handle] {
  background-color: #444EE6;
  outline: none;
}

[data-reach-slider-track] {
  background-color: #E0E0E0;
}

.advisor-title {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.advisor-title h1 {
  width: 578px;
  margin-top: 40px;
  margin-bottom: 0;
}
.advisor-title__subtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
}

/* stylelint-disable declaration-no-important */
.tooltip {
  position: relative;
  display: flex;
  align-items: center;
}
.tooltip__indicator {
  display: flex;
  cursor: pointer;
}
.tooltip__indicator img {
  margin-left: 16px;
}
.tooltip__card {
  position: absolute;
  display: block;
  width: 272px;
  min-height: 88px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #393939;
  box-shadow: 0 8px 20px 0 #ADB7C4;
  color: #FFF;
  font-family: "Work Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  opacity: 0;
  transition: all 0.3s ease-out;
  visibility: hidden;
}
.tooltip__card--right {
  top: -84px;
  left: 40px;
}
.tooltip__card--top {
  top: -168px;
  left: -96px;
}
.tooltip__card--bottom {
  bottom: -168px;
  left: -96px;
}
.tooltip__card span {
  width: 240px;
  height: 72px;
  color: #0D0E0E;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}
.tooltip:hover .tooltip__card, .tooltip:focus .tooltip__card {
  opacity: 1;
  visibility: visible;
}

/* stylelint-disable declaration-no-important */
.notification {
  position: fixed !important;
  z-index: 5;
  top: 24px;
  right: 0;
  left: 0;
  display: none !important;
  width: 800px;
  width: 608px;
  align-items: center;
  margin: 0 auto;
  animation: fade-in 1s forwards;
  opacity: 0;
  outline: none;
}
@media (max-width: 576px) {
  .notification {
    width: 320px;
  }
}
.notification__close {
  appearance: none;
  outline: none;
}
.notification--active {
  display: flex !important;
  animation: fade-in 1s forwards;
}
.notification--closing {
  animation: fade-out 0.3s forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.blog-banner {
  display: flex;
  flex-direction: column;
}
.blog-banner__header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.63;
}
.blog-banner__header .title {
  font-family: "MabryPro-Bold", sans-serif;
}
.blog-banner__header .action {
  display: inline-flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}
.blog-banner__header .action .arrow {
  margin-left: 8px;
  transition: all 0.2s ease;
}
.blog-banner__header .action:hover .arrow, .blog-banner__header .action:focus .arrow {
  margin-right: -8px;
  margin-left: 16px;
}
.blog-banner__header .action--light {
  color: #FFF;
}
.blog-banner__body {
  display: inline-flex;
}
.blog-banner__body .item {
  max-width: 224px;
  margin-right: 32px;
  color: #000;
  outline: none;
  text-decoration: none;
}
@media screen and (max-width: 992px) {
  .blog-banner__body .item {
    max-width: 186px;
    margin-right: 24px;
  }
}
.blog-banner__body .item:last-child {
  margin-right: 0;
}
.blog-banner__body .item__image {
  display: flex;
  overflow: hidden;
  height: 200px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 24px;
}
@media screen and (max-width: 992px) {
  .blog-banner__body .item__image {
    height: 166px;
  }
}
.blog-banner__body .item__image .thumbnail {
  transition: transform 0.5s ease;
}
.blog-banner__body .item__image:hover .thumbnail, .blog-banner__body .item__image:focus .thumbnail {
  transform: scale(1.2);
}
.blog-banner__body .item__description div:first-child {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
}
@media screen and (max-width: 992px) {
  .blog-banner__body .item__description div:first-child {
    line-height: 1.57;
  }
}
.blog-banner__body .item__description div:last-child {
  padding-right: 8px;
  font-family: "Mabry-Regular", sans-serif;
  font-size: 16px;
  line-height: 1.63;
}
@media screen and (max-width: 992px) {
  .blog-banner__body .item__description div:last-child {
    padding: 0;
    font-size: 14px;
    line-height: 1.57;
  }
}
.blog-banner__body .item__description--light {
  color: #FFF;
}

.environment-alert {
  position: fixed;
  z-index: 1001;
  top: 16px;
  left: 50%;
  display: flex;
  width: 320px;
  max-width: 320px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #6934FF;
  transform: translate(-160px);
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}
.environment-alert:focus, .environment-alert:hover {
  opacity: 0.5;
}
.environment-alert__icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background: url(../images/atm-test-enviroment-alert.svg) no-repeat 0 0;
}
.environment-alert__message {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
}

.card {
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: #FFF;
  color: #525252;
}
.card--squared-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.card--squared-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.card--squared-all {
  border-radius: 0;
}

.information-section {
  display: flex;
  width: 192px;
  flex-direction: column;
}
.information-section img {
  width: 21.5px;
  margin-right: 11px;
}
.information-section__description {
  display: flex;
  align-items: flex-start;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-wrap: nowrap;
}
.information-section__description--bold-description {
  margin-bottom: 10px;
  font-weight: 600;
}
.information-section__value {
  color: #444EE6;
  font-family: "Mabry-Regular", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 34px;
}
.information-section__subtitle {
  color: #6F6F6F;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

.cancel-screen {
  position: fixed;
  z-index: 5;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #FFF;
  font-family: "Mabry-Regular", sans-serif;
}
.cancel-screen__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .cancel-screen__container:first-child {
    width: auto;
    margin: 0 24px;
  }
}
.cancel-screen__container img {
  margin-bottom: 24px;
}
.cancel-screen__container-question {
  max-width: 603px;
  font-family: "MabryPro-Bold", sans-serif;
  font-size: 42px;
  line-height: 1.24;
  text-align: center;
}
@media (max-width: 768px) {
  .cancel-screen__container-question {
    max-width: 544px;
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .cancel-screen__container-question {
    font-size: 24px;
  }
}
.cancel-screen__container-description {
  max-width: 864px;
  padding-top: 24px;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}
@media (max-width: 768px) {
  .cancel-screen__container-description {
    max-width: 544px;
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .cancel-screen__container-description {
    font-size: 16px;
  }
}
.cancel-screen__container-controls {
  margin-top: 40px;
}
.cancel-screen__container-controls .button--link {
  border-bottom: 1px solid transparent;
  margin-top: 24px;
}
.cancel-screen__button--cancel {
  border: transparent;
  margin-top: 24px;
  background: transparent;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  text-decoration: none;
}
.cancel-screen__button--cancel:focus, .cancel-screen__button--cancel:hover {
  border-bottom: 1px dotted #000;
}
.cancel-screen__button--small {
  width: 192px;
  margin-bottom: 24px;
}

.advisor-section {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: #F3F3F3;
  color: #000;
}
.advisor-section--old {
  display: flex;
  min-height: calc(100vh - 220px);
  flex-direction: column;
  justify-content: center;
  background-color: #FFF;
  color: #000;
}
@media (max-width: 992px) {
  .advisor-section--old {
    margin-right: 48px;
    margin-left: 48px;
  }
}
@media (max-width: 768px) {
  .advisor-section--old {
    margin-right: 0;
    margin-left: 0;
  }
}
.advisor-section .container {
  max-width: 1170px;
  margin: 0 auto;
}

.advisor-content {
  font-family: "Work Sans", sans-serif;
}
.advisor-content h2 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}
.advisor-content p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.advisor-form {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 576px) {
  .advisor-form {
    width: auto;
    margin-right: 24px;
    margin-left: 24px;
  }
}
.advisor-form-small {
  max-width: 280px;
}
.advisor-form-medium {
  max-width: 320px;
}
.advisor-form-large {
  max-width: 576px;
}
.advisor-form-left {
  margin-left: 0;
}

.advisor-information {
  display: inline-flex;
  max-width: 589px;
  min-height: 34px;
  border-radius: 4px;
  margin-bottom: 32px;
  background-color: rgba(68, 78, 230, 0.05);
  cursor: default;
  font-family: "Work Sans", sans-serif;
}
@media (max-width: 768px) {
  .advisor-information {
    max-width: 460px;
    margin-top: 24px;
    margin-right: 16px;
    margin-bottom: 24px;
  }
}
@media (max-width: 576px) {
  .advisor-information {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    text-align: left;
  }
}
.advisor-information strong {
  display: contents;
}
.advisor-information--icon {
  width: 24px;
  margin-left: 24px;
  background: url("../images/atm-icn-bell-black.svg") no-repeat center transparent;
}
.advisor-information--label {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 16px;
  margin: 8px 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}
.advisor-information--label strong {
  font-weight: 600;
}
@media (max-width: 576px) {
  .advisor-information--label {
    font-size: 14px;
    line-height: 1.43;
  }
}

.modal {
  position: fixed;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  color: black;
  font-family: "Gilroy", sans-serif;
  overflow-y: hidden;
}
.modal > * {
  outline: none;
}
.modal #legalDocumentContainer {
  overflow-y: scroll;
}
.modal .close {
  position: fixed;
  right: 25px;
}
.modal.outside {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  background-color: #FFF;
}
.modal.outside .close {
  top: 24px;
}
.modal__wrapper {
  width: 900px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .modal__wrapper {
    max-width: 544px;
  }
}

.legal-document {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.legal-document__container {
  max-width: 736px;
  margin: 80px auto 40px;
}
.legal-document__title {
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.24;
}
.legal-document__content {
  line-height: 1.75;
}
.legal-document__content p {
  font-size: 16px;
}
.legal-document__content h2 {
  font-size: 20px;
}

.advisor-profiles {
  padding-top: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.advisor-profiles--profile {
  min-width: 200;
  height: 100%;
  padding: 40px 16px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 5px 20px 0 rgba(180, 193, 203, 0.2);
  -webkit-transition: box-shadow 120ms ease-in-out;
  -moz-transition: box-shadow 120ms ease-in-out;
  -o-transition: box-shadow 120ms ease-in-out;
  transition: box-shadow 120ms ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.advisor-profiles--profile:hover, .advisor-profiles--profile:focus {
  box-shadow: 0 5px 24px 0 rgba(180, 193, 203, 0.5);
}
.advisor-profiles--title {
  color: #000;
  cursor: default;
  font-family: "Mabry-Regular", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.3;
}
.advisor-profiles--body {
  margin-top: 36px;
  color: #000;
  cursor: default;
  font-family: "Mabry-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 1.57;
}

/* stylelint-disable declaration-no-important */
.advisor {
  display: flex;
  height: 100vh;
}
.advisor-container {
  width: 100%;
  height: 100%;
}
.advisor-container__wrapper {
  height: 100%;
}

.question {
  margin-left: 16px;
}

.questionnaire {
  width: 100%;
  height: 100vh;
}
.questionnaire__close {
  display: flex;
  height: 76px;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .questionnaire__close {
    position: absolute;
    top: 21px;
    width: 100%;
    height: auto;
  }
}
.questionnaire__close img {
  margin-left: 32px;
}
@media (max-width: 768px) {
  .questionnaire__close img {
    margin-left: 32px;
  }
}
@media (max-width: 576px) {
  .questionnaire__close img {
    margin-left: 16px;
  }
}
.questionnaire__close-button {
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 10px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  background-image: url(../images/atm-cta-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
@media (max-width: 576px) {
  .questionnaire__close-button--white {
    width: 16px;
    height: 16px;
  }
}
.questionnaire__close-button--black {
  display: none;
}
@media (max-width: 576px) {
  .questionnaire__close-button--black {
    display: block;
    width: 16px;
    height: 16px;
    background-image: url(../images/atm-icn-close-black.svg);
  }
}
.questionnaire--question {
  position: relative;
  display: flex;
  max-width: 634px;
  align-items: center;
  margin-left: 16px;
}
@media (max-width: 992px) {
  .questionnaire--question {
    margin-left: 64px;
  }
}
@media (max-width: 768px) {
  .questionnaire--question {
    position: unset;
    top: 60px;
    max-width: 544px;
    margin-bottom: 40px;
    margin-left: 176px;
  }
}
@media (max-width: 768px) {
  .questionnaire--question {
    top: unset;
    max-width: 544px;
    margin-bottom: 40px;
    margin-left: 112px;
  }
}
@media (max-width: 576px) {
  .questionnaire--question {
    display: block;
    margin-bottom: 0;
    margin-left: 0;
  }
}
.questionnaire--options {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
@media (max-width: 768px) {
  .questionnaire--options {
    top: 0;
    margin-left: 176px;
  }
}
@media (max-width: 768px) {
  .questionnaire--options {
    top: unset;
    margin-left: 112px;
  }
}
@media (max-width: 576px) {
  .questionnaire--options {
    display: table;
    margin-left: 0;
  }
}
.questionnaire--options.portfolio-loss, .questionnaire--options.win-lose, .questionnaire--options.total-assets, .questionnaire--options.experience-investing-fix, .questionnaire--options.monthly-income {
  max-width: 320px;
}
@media (max-width: 768px) {
  .questionnaire--options.portfolio-loss, .questionnaire--options.win-lose, .questionnaire--options.total-assets, .questionnaire--options.experience-investing-fix, .questionnaire--options.monthly-income {
    width: 544px;
    max-width: 544px;
    margin-left: 112px;
  }
  .questionnaire--options.portfolio-loss .advisor-option, .questionnaire--options.win-lose .advisor-option, .questionnaire--options.total-assets .advisor-option, .questionnaire--options.experience-investing-fix .advisor-option, .questionnaire--options.monthly-income .advisor-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .questionnaire--options.portfolio-loss, .questionnaire--options.win-lose, .questionnaire--options.total-assets, .questionnaire--options.experience-investing-fix, .questionnaire--options.monthly-income {
    width: 100%;
    margin-left: 0;
  }
  .questionnaire--options.portfolio-loss .advisor-option, .questionnaire--options.win-lose .advisor-option, .questionnaire--options.total-assets .advisor-option, .questionnaire--options.experience-investing-fix .advisor-option, .questionnaire--options.monthly-income .advisor-option {
    margin: 0;
  }
}
.questionnaire--options.fall-percentage-fix .advisor-option-medium {
  min-width: 360px;
}
@media (max-width: 992px) {
  .questionnaire--options.fall-percentage-fix .advisor-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option {
    width: 372px;
    min-width: 0;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .questionnaire--options.fall-percentage-fix .advisor-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option {
    width: 644px;
    min-width: 0;
    margin: 0;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .radio-option--medium:nth-child(2n),
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .advisor-option--option-medium:nth-child(2n) {
    margin-right: 30px;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .radio-option--medium:nth-child(3n),
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .advisor-option--option-medium:nth-child(3n) {
    margin-right: 0;
  }
}
@media (max-width: 576px) {
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option,
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option {
    display: flex;
    width: auto;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option .radio-option--medium,
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option .advisor-option--option-medium,
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .radio-option--medium,
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .advisor-option--option-medium {
    width: 122px;
    height: 48px;
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option .radio-option--medium:nth-child(odd),
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option .advisor-option--option-medium:nth-child(odd),
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .radio-option--medium:nth-child(odd),
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .advisor-option--option-medium:nth-child(odd) {
    margin-left: 0;
  }
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option .radio-option--medium .advisor-option--bullet,
  .questionnaire--options.fall-percentage-fix .advisor-form .radio-option .advisor-option--option-medium .advisor-option--bullet,
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .radio-option--medium .advisor-option--bullet,
  .questionnaire--options.fall-percentage-fix .advisor-form .advisor-option .advisor-option--option-medium .advisor-option--bullet {
    top: 14px;
    left: 16px;
  }
}
.questionnaire--subtitle {
  display: block;
  max-width: 592px;
  margin: 0 80px 0 0;
  color: #000;
  font-family: "Mabry-Regular", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
}
@media (max-width: 768px) {
  .questionnaire--subtitle {
    max-width: 460px;
    margin: 16px 16px 32px 0;
    text-align: justify;
  }
}
@media (max-width: 576px) {
  .questionnaire--subtitle {
    margin: 0 24px 24px;
    font-size: 14px;
    font-weight: 400;
  }
}
.questionnaire .advisor-input-currency {
  max-width: 370px;
}
.questionnaire .advisor-input-years {
  max-width: 240px;
}
.questionnaire .welcome-fix {
  max-width: 665px;
  margin: 20px auto;
}
.questionnaire .initial-amount-fix {
  height: fit-content;
}
.questionnaire .initial-amount-fix .advisor-title--title {
  max-width: 670px;
}
@media (max-width: 768px) {
  .questionnaire .initial-amount-fix .advisor-title {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .questionnaire .initial-amount-fix .advisor-title--title {
    width: fit-content;
  }
}
.questionnaire .initial-amount-fix .questionnaire--subtitle {
  max-width: 576px;
}

/* stylelint-disable declaration-no-important */
.portfolios {
  width: 100%;
  max-width: 1056px;
}
.portfolios__header {
  width: 100%;
  max-width: 700px;
  margin: 0 auto 40px auto;
  text-align: center;
}
.portfolios__cards {
  display: flex;
  padding: 15px 0 15px 15px;
  margin-bottom: 40px;
}
.portfolios__cards--adjust {
  justify-content: space-between;
}
.portfolios__cards--overflow {
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.portfolios__content {
  width: 100%;
  max-width: 700px;
  margin: 0 auto 40px 0;
}

.portfolio-card {
  width: 250px !important;
  min-width: 250px;
  max-height: none !important;
  padding: 30px 20px !important;
  margin-right: 30px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15) !important;
  text-align: center;
}
.portfolio-card--clickable {
  cursor: pointer;
}
.portfolio-card--selected {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15) !important;
}
.portfolio-card__image {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px auto;
}

.gbm-logo {
  width: 100px;
  height: 21px;
}

.close {
  position: absolute;
  right: 24px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  background-image: url(../images/atm-cta-close.svg);
  background-repeat: no-repeat;
  cursor: pointer;
}
@media (max-width: 992px) {
  .close {
    right: 72px;
  }
}
@media (max-width: 768px) {
  .close {
    right: 24px;
  }
}

@media (max-width: 768px) {
  .advisor-section {
    margin-right: 0;
    margin-left: 0;
  }
}
.advisor-section .advisor-details {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.advisor-section .projected-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.advisor-section .projected-wrapper--old {
  margin-bottom: 40px;
}
.advisor-section .amount--projected {
  display: flex;
  width: 448px;
  margin-bottom: 32px;
}
.advisor-section .amount--initial {
  display: flex;
  width: 356px;
  max-width: 356px;
}
.advisor-section .projected-information {
  display: flex;
}
.advisor-section .initial-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
}
.advisor-section .initial-information .information-section:first-child {
  margin-bottom: 16px;
}
.advisor-section .complete-profile {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.advisor-section .portfolio-definition {
  display: flex;
  width: 448px;
  margin: 0 32px 32px 0;
}
.advisor-section .portfolio-definition__title {
  color: #000;
  font-weight: 600;
}
.advisor-section .portfolio-definition__description {
  width: 100%;
}
.advisor-section .portfolio-definition__info {
  display: flex;
  width: 100%;
  margin-top: 32px;
}
.advisor-section .portfolio-definition__info--old {
  display: inline-flex;
  width: 100%;
  max-width: 1056px;
  box-sizing: border-box;
  justify-content: space-between;
  padding-bottom: 24px;
  margin: 0 auto;
}
.advisor-section .portfolio-definition__info .information-section:first-child {
  margin-right: 16px;
}
.advisor-section .advisor-results__wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.advisor-section .advisor-results__info--old {
  display: inline-flex;
  width: 100%;
  max-width: 1056px;
  box-sizing: border-box;
  justify-content: space-between;
  padding-bottom: 24px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .advisor-section .advisor-results__info--old {
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 0 24px 0 48px;
    overflow-x: hidden;
  }
}
.advisor-section .chart-instruments {
  display: inline-flex;
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.advisor-section .chart-instruments p {
  margin: 0;
}
.advisor-section .chart-instruments::before {
  position: relative;
  top: 6px;
  right: 0;
  display: inline-block;
  width: 4px;
  height: 16px;
  content: "";
}
.advisor-section .chart-instruments--equity::before {
  background-color: #1C12B1;
}
.advisor-section .chart-instruments--debt::before {
  background-color: #444EE6;
}
.advisor-section .chart-instruments--type-percentage {
  display: flex;
  width: 20%;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}
.advisor-section .chart-instruments--type-title {
  display: flex;
  width: 85%;
  align-items: center;
  margin-left: 12px;
  font-size: 14px;
}
.advisor-section .advisor-projected {
  display: flex;
  width: 100%;
  max-width: 1056px;
  min-height: 112px;
  justify-content: space-around;
  border-radius: 4px;
  background-color: rgba(68, 78, 230, 0.05);
  font-family: "Mabry-Regular", sans-serif;
}
.advisor-section .advisor-projected--section {
  display: flex;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
}
.advisor-section .advisor-projected--section__description {
  margin-bottom: 8px;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.advisor-section .advisor-projected--section__amount {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.63;
}
.advisor-section .advisor-projected--section__amount .cents {
  font-size: 20px;
}
.advisor-section .advisor-projected .section-wrapper {
  display: flex;
  width: 25%;
  border-right: solid 1px #FFF;
  margin-left: 16px;
}
.advisor-section .advisor-projected .section-wrapper:last-child {
  border-right: none;
}
@media (max-width: 768px) {
  .advisor-section .advisor-projected {
    width: 90%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .advisor-section .advisor-projected--section {
    width: 100%;
    padding: 16px;
    margin: 0;
  }
  .advisor-section .advisor-projected .section-wrapper {
    width: 100%;
    border-right: none;
    margin-left: 0;
  }
}
.advisor-section .advisor-cagr {
  display: flex;
  width: 448px;
  align-items: center;
  justify-content: center;
  font-family: "Mabry-Regular", sans-serif;
}
@media (max-width: 576px) {
  .advisor-section .advisor-cagr {
    width: auto;
    margin-right: 24px;
    margin-left: 24px;
  }
}
.advisor-section .advisor-cagr--description {
  max-width: 800px;
  padding: 16px;
  border-radius: 4px;
  margin: 0;
  background-color: #CBCBFA;
  color: #000;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  line-height: 1.67;
  text-align: justify;
}
@media (max-width: 768px) {
  .advisor-section .advisor-cagr--description {
    width: 90%;
  }
}
.advisor-section .advisor-results--description {
  color: #121212;
  font-size: 14px;
  text-align: left;
}
.advisor-section .advisor-results--table {
  width: 100%;
}
.advisor-section .advisor-results--chart {
  width: 100%;
}
.advisor-section .advisor-results--chart-old {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  vertical-align: text-top;
}
@media (max-width: 768px) {
  .advisor-section .advisor-results--chart {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .advisor-section .advisor-results--chart {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.advisor-section .advisor-results--chart-instruments p {
  margin: 0;
}
.advisor-section .advisor-results--chart-instruments--type-percentage {
  display: flex;
  width: 20%;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}
.advisor-section .advisor-results--chart-instruments--type-title {
  display: flex;
  width: 85%;
  align-items: center;
  font-size: 14px;
}
.advisor-section .advisor-results--chart-instruments--type-debt {
  display: inline-flex;
  width: 100%;
  margin-top: 16px;
}
.advisor-section .advisor-results--chart-instruments--type-debt::before {
  position: relative;
  top: 6px;
  right: 8px;
  display: inline-block;
  width: 4px;
  height: 16px;
  background-color: #444EE6;
  content: "";
}
.advisor-section .advisor-results--chart-instruments--type-equity {
  display: inline-flex;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #E0E0E0;
  margin-top: 16px;
}
.advisor-section .advisor-results--chart-instruments--type-equity::before {
  position: relative;
  top: 6px;
  right: 8px;
  display: inline-block;
  width: 4px;
  height: 16px;
  background-color: #1C12B1;
  content: "";
}
.advisor-section .advisor-results--chart-results {
  width: 100%;
  height: 250px;
  margin-bottom: 24px;
}
.advisor-section .advisor-results--chart-results .composition-item {
  cursor: pointer;
  opacity: 1;
  transition: all ease 0.3s;
}
.advisor-section .advisor-results--chart-results .composition-item--opacity {
  opacity: 0.1;
}
.advisor-section .advisor-results--chart-results .composition-item.debt {
  fill: #444EE6;
}
.advisor-section .advisor-results--chart-results .composition-item.equity {
  fill: #1C12B1;
}
.advisor-section .advisor-results--chart-results .composition-chart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.advisor-section .advisor-results--chart-results .composition-chart text {
  display: flex;
  flex-direction: column-reverse;
}
.advisor-section .advisor-results--chart-results .composition-chart text .center-text-name {
  font-family: "Mabry-Regular", sans-serif;
  font-size: 14px;
}
.advisor-section .advisor-results--chart-results .composition-chart text .center-text-percentage {
  font-family: "Mabry-Regular", sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.advisor-section .advisor-results--title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 16px;
}
.advisor-section .results-title {
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.advisor-section .results-title img {
  width: 88px;
}
@media (max-width: 576px) {
  .advisor-section .results-title img {
    margin-top: 0;
  }
}
.advisor-section .results-title .advisor-title {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;
}
@media (max-width: 576px) {
  .advisor-section .results-title .advisor-title {
    margin-top: 24px;
  }
}
.advisor-section .results-title .advisor-title-centered .advisor-title--title {
  margin-bottom: 16px;
}
.advisor-section .results-title .advisor-title-centered .advisor-title--pretitle {
  max-width: 736px;
}
.advisor-section .button:first-child {
  margin-bottom: 24px;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  margin: 0;
  font-size: 100%;
  text-align: left;
  vertical-align: baseline;
}

table {
  border: 0;
  margin: 0;
  border-collapse: collapse;
  border-spacing: 0;
  outline: 0;
}

.advisor-table {
  display: table;
  width: 100%;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  border-bottom: 1px solid #E0E0E0;
  margin-bottom: 16px;
  border-collapse: separate;
  color: #000;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
}
.advisor-table--header, .advisor-table--cell {
  color: #000;
  vertical-align: middle;
}
.advisor-table--header {
  padding: 16px 0;
  color: #7D8387;
  font-size: 14px;
  font-weight: 500;
}
.advisor-table--cell {
  height: 32px;
  font-size: 14px;
  font-weight: 400;
}
.advisor-table--cell__kind {
  display: flex;
  width: auto;
  align-items: center;
  margin-bottom: 12px;
  font-family: "Mabry-Regular", sans-serif;
}
.advisor-table--cell__kind .indicator {
  width: 4px;
  height: 16px;
  margin-right: 8px;
  background-color: #1C12B1;
}
.advisor-table--cell__kind .indicator.debt {
  background-color: #444EE6;
}
.advisor-table--cell__kind.debt {
  margin-top: 24px;
}
.advisor-table--cell__name {
  width: 35%;
}
.advisor-table--cell__value {
  width: 10%;
  text-align: right;
}
.advisor-table--cell.equity .tooltip__card {
  left: 105px;
}
.advisor-table--cell.debt .tooltip__card {
  left: 75px;
}
.advisor-table--cell:last-child {
  width: 100px;
  font-weight: 500;
}
.advisor-table--borderless {
  border-bottom: 0;
}
@media (max-width: 768px) {
  .advisor-table {
    padding-right: 32px;
  }
  .advisor-table:last-child {
    border-bottom: none;
  }
}

.welcome-screen {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  animation: enter 2s forwards;
  background-color: #FFF;
  color: #000;
  font-family: "Mabry-Regular", sans-serif;
}
@keyframes enter {
  0% {
    transform: scale(2, 2);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes slide {
  100% {
    display: none;
    width: 0;
    margin-left: 100%;
  }
}
.welcome-screen__container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .welcome-screen__container {
    padding: 0 144px;
  }
}
@media (min-width: 992px) {
  .welcome-screen__container {
    padding: 0 300px;
  }
}
.welcome-screen__container-icon {
  margin-bottom: 20px;
}
.welcome-screen__container-icon img {
  width: 87px;
  height: 20px;
}
.welcome-screen__container-mark {
  margin-bottom: 34px;
  font-size: 20px;
  font-weight: 400;
  line-height: 0.6;
}
@media (min-width: 768px) {
  .welcome-screen__container-mark {
    margin-bottom: 68px;
  }
}
.welcome-screen__container h1 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 28px;
}
@media (min-width: 768px) {
  .welcome-screen__container h1 {
    width: 90%;
    margin-bottom: 24px;
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .welcome-screen__container h1 {
    width: 80%;
  }
}
@media (min-width: 1400px) {
  .welcome-screen__container h1 {
    width: 60%;
  }
}
.welcome-screen__container-message {
  margin-bottom: 40px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.64;
}
@media (min-width: 768px) {
  .welcome-screen__container-message {
    width: 90%;
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .welcome-screen__container-message {
    width: 80%;
  }
}
@media (min-width: 1400px) {
  .welcome-screen__container-message {
    width: 60%;
  }
}
.welcome-screen.hidden {
  animation: slide 0.5s forwards;
  animation-delay: 0.1s;
}
.welcome-screen.hidden .welcome-screen__container {
  display: none;
}
.welcome-screen__close {
  position: absolute;
  top: 21px;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-between;
}
.welcome-screen__close img {
  margin-left: 32px;
}
.welcome-screen__close-button {
  z-index: 1;
  width: 28px;
  height: 28px;
  border: none;
  margin-right: 32px;
  background-color: transparent;
  background-image: url(../images/atm-cta-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.welcome-screen__close-button--white {
  width: 16px;
  height: 16px;
}
.welcome-screen__close-button--black {
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(../images/atm-icn-close-black.svg);
}
.welcome-screen .mark__wealth .half-circle :first-child .fill {
  animation-delay: 1.5s;
}
.welcome-screen .mark__wealth .half-circle :last-child .fill {
  animation-delay: 2s;
}

.maintenance-mode {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  background-image: url(../images/@2x-not-found-bg.png);
  background-position: center;
  background-size: cover;
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}
.maintenance-mode .gbm-logo {
  width: 124px;
  height: 25px;
  margin-left: 354px;
}
@media (max-width: 1200px) {
  .maintenance-mode .gbm-logo {
    margin-left: 58px;
  }
}
@media (max-width: 992px) {
  .maintenance-mode .gbm-logo {
    margin-bottom: 124px;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .maintenance-mode .gbm-logo {
    margin-bottom: 96px;
  }
}
.maintenance-mode .content {
  display: flex;
  flex-direction: column;
  margin-top: 156px;
  margin-left: 136px;
}
@media (max-width: 576px) {
  .maintenance-mode .content {
    overflow: hidden;
  }
}
@media (max-width: 992px) {
  .maintenance-mode .content {
    margin-top: 104px;
    margin-left: 80px;
  }
}
@media (max-width: 576px) {
  .maintenance-mode .content {
    margin-top: 124px;
    margin-left: 17px;
  }
}
.maintenance-mode .content__header {
  display: inline-flex;
}
@media (max-width: 992px) {
  .maintenance-mode .content__header {
    display: flex;
    flex-direction: column-reverse;
  }
}
.maintenance-mode .content__header .title {
  max-width: 608px;
  margin-bottom: 24px;
  font-family: "MabryPro-Bold", sans-serif;
  font-size: 56px;
  line-height: 1.18;
}
@media (max-width: 1200px) {
  .maintenance-mode .content__header .title {
    max-width: 554px;
    font-size: 48px;
    line-height: 1.21;
  }
}
@media (max-width: 992px) {
  .maintenance-mode .content__header .title {
    max-width: 416px;
    margin-bottom: 16px;
  }
}
@media (max-width: 576px) {
  .maintenance-mode .content__header .title {
    max-width: 320px;
    margin-bottom: 16px;
    font-size: 32px;
  }
}
.maintenance-mode .content__subtitle {
  max-width: 608px;
  margin-bottom: 120px;
  font-size: 20px;
  line-height: 1.5;
}
@media (max-width: 992px) {
  .maintenance-mode .content__subtitle {
    max-width: 441px;
  }
}
@media (max-width: 576px) {
  .maintenance-mode .content__subtitle {
    max-width: 320px;
    font-size: 14px;
  }
}
.maintenance-mode .content__actions {
  margin-bottom: 57px;
}
.maintenance-mode .content__actions .blog-banner {
  max-width: 736px;
}
@media (max-width: 992px) {
  .maintenance-mode .content__actions .blog-banner {
    max-width: 608px;
  }
}
@media (max-width: 576px) {
  .maintenance-mode .content__actions .blog-banner {
    max-width: 343px;
  }
  .maintenance-mode .content__actions .blog-banner__header .action span {
    display: none;
  }
  .maintenance-mode .content__actions .blog-banner__body {
    overflow-y: scroll;
  }
}

.confirm-save {
  z-index: 5;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.confirm-save__title {
  margin-bottom: 24px;
}
.confirm-save__description {
  width: 764px;
  margin-bottom: 48px;
  text-align: center;
}
.confirm-save__image {
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
}

.portfolio-properties {
  width: 434px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .portfolio-properties {
    width: 80%;
  }
}
@media (max-width: 576px) {
  .portfolio-properties {
    width: 100%;
  }
}
.portfolio-properties__title {
  display: flex;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.portfolio-properties__bars {
  margin-bottom: 8px;
}
.portfolio-properties__levels {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
}
.portfolio-properties .tooltip {
  width: fit-content;
}
.portfolio-properties .tooltip__card {
  box-shadow: none;
}
.portfolio-properties .tooltip__indicator {
  border-bottom: none;
  color: #FFF;
}
.portfolio-properties .tooltip__indicator img {
  margin-left: 8px;
}

.simulator {
  display: flex;
  min-height: 100vh;
}
@media (max-width: 768px) {
  .simulator {
    padding: 0 24px;
  }
}
.simulator--dark {
  background-color: #141414;
  color: #FFF;
}
.simulator--light {
  background-color: #FFF;
  color: #000;
}
.simulator__container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 576px) {
  .simulator__container {
    display: table;
    margin-top: 112px;
  }
  .simulator__container .btn-mobile {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 40px;
  }
}
.simulator__results {
  align-items: flex-start;
  padding-top: 80px;
}
@media (max-width: 576px) {
  .simulator__results {
    padding-top: 0;
    margin-bottom: 180px;
  }
}
.simulator__content {
  width: 450px;
}
@media (max-width: 768px) {
  .simulator__content {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .simulator__content {
    width: 100%;
  }
}
.simulator__content--stretch {
  padding-right: 50px;
}
@media (max-width: 576px) {
  .simulator__content--stretch {
    padding-right: 0;
  }
}
.simulator__content--right {
  width: 470px;
}
@media (max-width: 768px) {
  .simulator__content--right {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .simulator__content--right {
    width: 100%;
  }
}
.simulator__content a {
  color: inherit;
}
.simulator__content--chart {
  min-width: 493px;
  min-height: 382px;
  margin-left: -11px;
}
@media (max-width: 576px) {
  .simulator__content--chart {
    width: 320px;
    min-width: 320px;
    margin-left: 0;
  }
}
.simulator__form {
  width: 352px;
}
@media (max-width: 576px) {
  .simulator__form {
    width: 100%;
  }
}
.simulator__actions {
  display: flex;
  padding-top: 40px;
  margin-bottom: 64px;
}
.simulator__actions:first-child {
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .simulator__actions {
    display: grid;
    justify-content: center;
    padding-top: 80px;
    margin-bottom: 16px;
  }
  .simulator__actions:nth-child(2) {
    display: flex;
    padding-top: 24px;
  }
}
.simulator__actions .tooltip {
  width: fit-content;
}
.simulator__actions .tooltip__card {
  z-index: 1;
  width: auto;
  min-height: auto;
  box-shadow: none;
  font-size: 12px;
  white-space: nowrap;
}
.simulator__actions .tooltip__card--down {
  bottom: -45px;
}
@media (max-width: 576px) {
  .simulator__actions .tooltip__card--down:last-child {
    right: 0;
  }
}
.simulator__actions .tooltip__indicator {
  border-bottom: none;
  color: #FFF;
}
.simulator__actions .tooltip__indicator img {
  margin-left: 8px;
}
.simulator__btn-start {
  margin-right: 36px;
}
@media (max-width: 576px) {
  .simulator__btn-start {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.simulator__btn-invite, .simulator__btn-send, .simulator__btn-try {
  border: none;
  background-color: transparent;
  color: #FFF;
}
.simulator__btn-test, .simulator__btn-next, .simulator__btn-again, .simulator__btn-start {
  color: #000;
}
.simulator .product__title {
  margin-bottom: 64px;
}
.simulator .product__description {
  margin-bottom: 48px;
}
.simulator .product__disclaimer {
  margin-top: 38px;
  font-size: 10px;
  line-height: 15px;
}
.simulator .button-icon {
  display: inline-flex;
  width: 48px;
  height: 48px;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 50%;
  margin: 0 24px 0 0;
  background-color: #262626;
  color: #FFF;
  cursor: pointer;
}
@media (max-width: 576px) {
  .simulator .button-icon {
    margin: 0 12px;
  }
}
.simulator .button-icon--users {
  background-image: url("../images/icons/atm-icn-users.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.simulator .button-icon--users:hover, .simulator .button-icon--users:focus {
  background-color: #FFF;
  background-image: url("../images/icons/atm-icn-users-black.svg");
}
.simulator .button-icon--email {
  background-image: url("../images/icons/atm-icn-sm-mail.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.simulator .button-icon--email:hover, .simulator .button-icon--email:focus {
  background-color: #FFF;
  background-image: url("../images/icons/atm-icn-sm-mail-black.svg");
  background-position: center;
  background-size: auto;
}
.simulator .button-icon--share {
  background-image: url("../images/icons/atm-icn-sm-share.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.simulator .button-icon--share:hover, .simulator .button-icon--share:focus {
  background-color: #FFF;
  background-image: url("../images/icons/atm-icn-sm-share-black.svg");
  background-position: center;
  background-size: auto;
}
.simulator .button__share {
  top: -130px;
  right: -180px;
}
@media (max-width: 576px) {
  .simulator .button__share {
    top: -192px;
    right: 0;
    left: -144px;
    margin: 0 auto;
  }
}
.simulator .button__share-option {
  color: #000;
  text-decoration: none;
}
.simulator .button__share-option--messenger::before {
  background-image: url("../images/icons/atm-icn-sm-facebook.svg");
}
.simulator .button--with-share {
  width: fit-content;
}
.simulator .mt-16 {
  margin-top: 16px;
}
.simulator .mt-40 {
  margin-top: 40px;
}
.simulator .mb-60 {
  margin-bottom: 60px;
}
.simulator .mb-80 {
  margin-bottom: 80px;
}
.simulator .input-wrapper:first-child {
  margin-top: 0;
}
.simulator .input__label {
  margin-top: 16px;
}
.simulator .input__input {
  background-color: transparent;
  color: #FFF;
  text-align: left;
}
.simulator .input__input--big {
  padding-bottom: 16px;
  padding-left: 0;
  font-size: 20px;
}
.simulator .input__input--big:focus {
  border-color: #FFF;
}
.simulator .input--question {
  width: 352px;
}
@media (max-width: 576px) {
  .simulator .input--question {
    width: 100%;
  }
}
.simulator .input--currency .input__input--big {
  padding-left: 16px;
}
.simulator .input--years, .simulator .input--currency {
  width: 352px;
}
@media (max-width: 576px) {
  .simulator .input--years, .simulator .input--currency {
    width: 100%;
  }
}
.simulator .input--years::before, .simulator .input--years::after, .simulator .input--currency::before, .simulator .input--currency::after {
  top: 4px;
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
}
.simulator .input--currency-usd::after {
  content: "USD";
}
.simulator .input--years-eng::after {
  content: "YEARS";
}
.simulator .input--error > :first-child {
  border-color: #E96B70;
}
.simulator .input--error-label {
  color: #E96B70;
  font-size: 12px;
}
.simulator .checkbox input[type=checkbox].checkbox__input + label::before {
  border-color: #FFF;
  background: transparent;
}
.simulator .checkbox input[type=checkbox].checkbox__input:checked + label::before {
  border-color: #FFF;
  background: url("../images/icons/atm-icn-check.svg") no-repeat 50%;
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
}

.positive {
  color: #24A148;
}

.negative {
  color: #DA1E28;
}

.questionnaire-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: 300px;
  overflow-x: hidden;
}
@media (max-width: 992px) {
  .questionnaire-container {
    margin-left: 144px;
  }
}
@media (max-width: 768px) {
  .questionnaire-container {
    margin-left: 80px;
  }
}
.questionnaire-container .column {
  width: auto;
}
@media (max-width: 768px) {
  .questionnaire-container {
    display: flex;
    flex-direction: column;
  }
}

.advisor-title + .advisor-form {
  margin-top: 40px;
}

.advisor-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
}
@media (max-width: 768px) {
  .advisor-results {
    margin-top: -24px;
  }
}
.advisor-results__chart-description:first-child {
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 13px;
}
.advisor-results__controls {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}
@media (max-width: 576px) {
  .advisor-results__controls .advisor-button--primary {
    position: relative;
    top: 0;
    right: 0;
  }
}
.advisor-results__controls .advisor-button {
  margin-top: 8px;
}
.advisor-results__controls .checkbox {
  margin-bottom: 32px;
}
.advisor-results__controls .link {
  outline: none;
}
.advisor-results__controls--fixed {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 144px;
  justify-content: center;
  padding: 0;
  border-top: 1px solid #E1E7F0;
  background-color: #FFF;
  box-shadow: 0 0 2px 1px rgba(137, 147, 164, 0.3);
  opacity: 1;
  transition: all 0.2s ease-in;
}
.advisor-results__controls--fixed-hidden {
  z-index: -1;
  opacity: 0;
}
.advisor-results__controls--fixed .link {
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.advisor-results__controls--fixed .link:hover, .advisor-results__controls--fixed .link:focus {
  border: none;
}
.advisor-results__info, .advisor-results--info {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .advisor-results__info, .advisor-results--info {
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 0 24px 0 48px;
    overflow-x: hidden;
  }
}
.advisor-results__label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
.advisor-results--chart {
  display: flex;
  flex-direction: column;
  vertical-align: text-top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.advisor-results--chart > img {
  width: auto;
  max-width: 258px;
  height: auto;
  margin-top: 24px;
}
.advisor-results--table {
  display: inline-block;
  max-width: 600px;
  vertical-align: text-top;
}
@media (max-width: 768px) {
  .advisor-results--table {
    margin-top: 80px;
    margin-left: 34px;
  }
  .advisor-results--table:last-child {
    border-bottom: none;
  }
}
@media (max-width: 992px) {
  .advisor-results--table {
    max-width: 352px;
  }
}
.advisor-results--title {
  margin-bottom: 40px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.38;
  text-align: left;
}
.advisor-results--description {
  margin-bottom: 16px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
}
.advisor-results__profile {
  display: flex;
  width: 320px;
  flex-direction: column;
}
.advisor-results__profile--old {
  display: flex;
  max-width: 320px;
  flex-direction: column;
}
@media (max-width: 992px) {
  .advisor-results__profile {
    max-width: 320px;
  }
}
.advisor-results__profile-title {
  margin-bottom: 24px;
}
.advisor-results__profile-bottom {
  margin-top: 24px;
}
.advisor-results__profile-bottom, .advisor-results__profile-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.advisor-results__profile-description {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
}
@media (max-width: 768px) {
  .advisor-results__profile-description {
    padding-right: 32px;
  }
}

.chart-description__text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.advisor-results__disclaimer {
  display: none;
  max-width: 780px;
  margin: 0 auto;
  margin-top: 70px;
}

.advisor-results__sign,
.advisor-results__paragraph {
  display: inline-block;
  margin-bottom: 24px;
  font-size: 14px;
}

.advisor-results__sign {
  display: block;
  max-width: 220px;
  border-top: 1px solid #000;
  margin: 0 auto;
  margin-top: 80px;
}

.results-title {
  display: flex;
  justify-content: center;
}

.chart {
  display: flex;
  justify-content: center;
}

.logo-results {
  display: flex;
  height: 100px;
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .logo-results {
    display: flex;
    height: 76px;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    margin-left: 0;
  }
  .logo-results + .close {
    top: 24px;
    right: 32px;
  }
}
@media (max-width: 576px) {
  .logo-results {
    margin-bottom: 24px;
  }
  .logo-results .advisor-header--logo {
    display: block;
  }
  .logo-results + .close {
    top: 24px;
    right: 24px;
  }
}
.logo-results .advisor-progress {
  position: absolute;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.columns {
  display: flex;
  margin: 0;
}
.columns:last-child {
  margin-bottom: 0;
}
@media (max-width: 576px) {
  .columns.is-centered {
    justify-content: flex-start;
  }
}

