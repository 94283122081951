.gbm-logo {
  width: 100px;
  height: 21px;
}

.close {
  position: absolute;
  right: 24px;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  background-image: url(../images/atm-cta-close.svg);
  background-repeat: no-repeat;
  cursor: pointer;

  @media (max-width: $md) {
    right: 72px;
  }

  @media (max-width: $sm) {
    right: 24px;
  }
}

.advisor-section {
  @media (max-width: $sm) {
    margin-right: 0;
    margin-left: 0;
  }

  .advisor-details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .projected-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--old {
      margin-bottom: 40px;
    }
  }

  .amount {
    &--projected {
      display: flex;
      width: 448px;
      margin-bottom: 32px;
    }

    &--initial {
      display: flex;
      width: 356px;
      max-width: 356px;
    }
  }

  .projected-information {
    display: flex;
  }

  .initial-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 16px;

    .information-section:first-child {
      margin-bottom: 16px;
    }
  }

  .complete-profile {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .portfolio-definition {
    display: flex;
    width: 448px;
    margin: 0 32px 32px 0;

    &__title {
      color: $black;
      font-weight: $gbm-bold;
    }

    &__description {
      width: 100%;
    }

    &__info {
      display: flex;
      width: 100%;
      margin-top: 32px;

      &--old {
        display: inline-flex;
        width: 100%;
        max-width: 1056px;
        box-sizing: border-box;
        justify-content: space-between;
        padding-bottom: 24px;
        margin: 0 auto;
      }

      .information-section:first-child {
        margin-right: 16px;
      }
    }
  }

  .advisor-results {
    &__wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }

    &__info {
      &--old {
        display: inline-flex;
        width: 100%;
        max-width: 1056px;
        box-sizing: border-box;
        justify-content: space-between;
        padding-bottom: 24px;
        margin: 0 auto;

        @media (max-width: $sm) {
          width: 100%;
          flex-direction: row;
          align-items: center;
          padding: 0 24px 0 48px;
          overflow-x: hidden;
        }
      }
    }
  }

  .chart-instruments {
    display: inline-flex;
    width: 100%;
    margin-top: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: $gbm-bold;
    line-height: 24px;

    p {
      margin: 0;
    }

    &::before {
      position: relative;
      top: 6px;
      right: 0;
      display: inline-block;
      width: 4px;
      height: 16px;
      content: "";
    }

    &--equity {
      &::before {
        background-color: $equity;
      }
    }

    &--debt {
      &::before {
        background-color: $wm-blue;
      }
    }

    &--type {
      &-percentage {
        display: flex;
        width: 20%;
        height: 28px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
      }

      &-title {
        display: flex;
        width: 85%;
        align-items: center;
        margin-left: 12px;
        font-size: 14px;
      }
    }
  }

  .advisor {
    &-projected {
      display: flex;
      width: 100%;
      max-width: 1056px;
      min-height: 112px;
      justify-content: space-around;
      border-radius: 4px;
      background-color: rgba(68, 78, 230, 0.05);
      font-family: $font-stack;

      &--section {
        display: flex;
        width: fit-content;
        flex-direction: column;
        justify-content: center;

        &__description {
          margin-bottom: 8px;
          font-family: $font-stack-google;
          font-size: 14px;
          font-weight: $gbm-medium;
        }

        &__amount {
          font-size: 32px;
          font-weight: 500;
          line-height: 1.63;

          .cents {
            font-size: 20px;
          }
        }
      }

      .section-wrapper {
        display: flex;
        width: 25%;
        border-right: solid 1px $white;
        margin-left: 16px;

        &:last-child {
          border-right: none;
        }
      }

      @media (max-width: $sm) {
        width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &--section {
          width: 100%;
          padding: 16px;
          margin: 0;
        }

        .section-wrapper {
          width: 100%;
          border-right: none;
          margin-left: 0;
        }
      }
    }

    &-cagr {
      display: flex;
      width: 448px;
      align-items: center;
      justify-content: center;
      font-family: $font-stack;

      @media (max-width: $xs) {
        width: auto;
        margin-right: 24px;
        margin-left: 24px;
      }

      &--description {
        max-width: 800px;
        padding: 16px;
        border-radius: 4px;
        margin: 0;
        background-color: $disclaimer-box;
        color: $black;
        font-family: $font-stack-google;
        font-size: 12px;
        line-height: 1.67;
        text-align: justify;

        @media (max-width: $sm) {
          width: 90%;
        }
      }
    }

    &-results {
      &--description {
        color: $cod-gray;
        font-size: 14px;
        text-align: left;
      }

      &--table {
        width: 100%;
      }

      &--chart {
        width: 100%;

        &-old {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          vertical-align: text-top;
        }

        @media (max-width: $sm) {
          margin-top: 40px;
          margin-bottom: 40px;
        }

        @media (max-width: $sm) {
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }

        &-instruments {
          p {
            margin: 0;
          }

          &--type {
            &-percentage {
              display: flex;
              width: 20%;
              height: 28px;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              font-size: 14px;
              text-align: center;
            }

            &-title {
              display: flex;
              width: 85%;
              align-items: center;
              font-size: 14px;
            }

            &-debt {
              display: inline-flex;
              width: 100%;
              margin-top: 16px;

              &::before {
                position: relative;
                top: 6px;
                right: 8px;
                display: inline-block;
                width: 4px;
                height: 16px;
                background-color: $wm-blue;
                content: "";
              }
            }

            &-equity {
              display: inline-flex;
              width: 100%;
              padding-bottom: 16px;
              border-bottom: 1px solid $instruments-separator;
              margin-top: 16px;

              &::before {
                position: relative;
                top: 6px;
                right: 8px;
                display: inline-block;
                width: 4px;
                height: 16px;
                background-color: $equity;
                content: "";
              }
            }
          }
        }

        &-results {
          width: 100%;
          height: 250px;
          margin-bottom: 24px;

          .composition-item {
            cursor: pointer;
            opacity: 1;
            transition: all ease 0.3s;

            &--opacity {
              opacity: 0.1;
            }

            &.debt {
              fill: $wm-blue;
            }

            &.equity {
              fill: $equity;
            }
          }

          .composition-chart {
            display: flex;
            align-items: center;
            justify-content: center;

            text {
              display: flex;
              flex-direction: column-reverse;

              .center-text {
                &-name {
                  font-family: $font-stack;
                  font-size: 14px;
                }

                &-percentage {
                  font-family: $font-stack;
                  font-size: 24px;
                  font-weight: $gbm-semibold;
                }
              }
            }
          }
        }
      }

      &--title {
        width: 100%;
        margin-top: 0;
        margin-bottom: 16px;
      }
    }
  }

  .results-title {
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    img {
      width: 88px;

      @media (max-width: $xs) {
        margin-top: 0;
      }
    }

    .advisor-title {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      text-align: center;

      @media (max-width: $xs) {
        margin-top: 24px;
      }
    }

    .advisor-title-centered {
      .advisor-title {
        &--title {
          margin-bottom: 16px;
        }

        &--pretitle {
          max-width: 736px;
        }
      }
    }
  }

  .button {
    &:first-child {
      margin-bottom: 24px;
    }
  }
}
