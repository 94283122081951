.advisor-form {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: $xs) {
    width: auto;
    margin-right: 24px;
    margin-left: 24px;
  }

  &-small {
    max-width: 280px;
  }

  &-medium {
    max-width: 320px;
  }

  &-large {
    max-width: $xs;
  }

  &-left {
    margin-left: 0;
  }
}
