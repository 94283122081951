.advisor-sidebar {
  display: flex;
  width: 48px;
  justify-content: center;
  background-color: $wm-blue;
  transition: all 0.2s ease-in;

  @media (max-width: $sm) {
    position: fixed;
    right: 0;
    width: 48px;
    height: 100vh;
  }

  @media (max-width: $sm),
    (max-width: $md) {
    width: 48px;
  }

  @media (max-width: $xs) {
    display: none;
  }
}
