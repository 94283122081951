.advisor-section {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: $background-secondary;
  color: $black;

  &--old {
    display: flex;
    min-height: calc(100vh - 220px);
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    color: #000;

    @media (max-width: $md) {
      margin-right: 48px;
      margin-left: 48px;
    }

    @media (max-width: $sm) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
  }
}

.advisor-content {
  font-family: $font-stack-google;

  h2 {
    color: $black;
    font-size: 20px;
    font-weight: $gbm-extrabold;
    line-height: 1;
  }

  p {
    color: $black;
    font-size: 16px;
    font-weight: $gbm-medium;
    line-height: 1.5;
  }
}

.align-center {
  margin-right: auto;
  margin-left: auto;
}
