.modal {
  position: fixed;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  color: black;
  font-family: "Gilroy", sans-serif;
  overflow-y: hidden;

  > * {
    outline: none;
  }

  #legalDocumentContainer {
    overflow-y: scroll;
  }

  .close {
    position: fixed;
    right: 25px;
  }

  &.outside {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    background-color: $white;

    .close {
      top: 24px;
    }
  }

  &__wrapper {
    width: 900px;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: $sm) {
      max-width: 544px;
    }
  }
}

.legal-document {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &__container {
    max-width: 736px;
    margin: 80px auto 40px;
  }

  &__title {
    margin-bottom: 40px;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.24;
  }

  &__content {
    line-height: 1.75;

    p {
      font-size: 16px;
    }

    h2 {
      font-size: 20px;
    }
  }
}
