table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  margin: 0;
  font-size: 100%;
  text-align: left;
  vertical-align: baseline;
}

table {
  border: 0;
  margin: 0;
  border-collapse: collapse;
  border-spacing: 0;
  outline: 0;
}

.advisor-table {
  display: table;
  width: 100%;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  border-bottom: 1px solid $instruments-separator;
  margin-bottom: 16px;
  border-collapse: separate;
  color: $black;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;

  &--header,
  &--cell {
    color: $black;
    vertical-align: middle;
  }

  &--header {
    padding: 16px 0;
    color: #7D8387;
    font-size: 14px;
    font-weight: $gbm-semibold;
  }

  &--cell {
    height: 32px;
    font-size: 14px;
    font-weight: $gbm-medium;

    &__kind {
      display: flex;
      width: auto;
      align-items: center;
      margin-bottom: 12px;
      font-family: $font-stack;

      .indicator {
        width: 4px;
        height: 16px;
        margin-right: 8px;
        background-color: $equity;

        &.debt {
          background-color: $wm-blue;
        }
      }

      &.debt {
        margin-top: 24px;
      }
    }

    &__name {
      width: 35%;
    }

    &__value {
      width: 10%;
      text-align: right;
    }

    &.equity {
      .tooltip__card {
        left: 105px;
      }
    }

    &.debt {
      .tooltip__card {
        left: 75px;
      }
    }

    &:last-child {
      width: 100px;
      font-weight: $gbm-semibold;
    }
  }

  &--borderless {
    border-bottom: 0;
  }

  @media (max-width: $sm) {
    padding-right: 32px;

    &:last-child {
      border-bottom: none;
    }
  }
}
