.portfolio-properties {
  width: 434px;
  margin-bottom: 40px;

  @media (max-width: $sm) {
    width: 80%;
  }

  @media (max-width: $xs) {
    width: 100%;
  }

  &__title {
    display: flex;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &__bars {
    margin-bottom: 8px;
  }

  &__levels {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .tooltip {
    width: fit-content;

    &__card {
      box-shadow: none;
    }

    &__indicator {
      border-bottom: none;
      color: $white;

      img {
        margin-left: 8px;
      }
    }
  }
}
