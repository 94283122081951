.welcome-screen {
  $class: &;

  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  animation: enter 2s forwards;
  background-color: $white;
  color: $black;
  font-family: $font-stack;

  @keyframes enter {
    0% {
      transform: scale(2, 2);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes slide {
    100% {
      display: none;
      width: 0;
      margin-left: 100%;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;

    @media (min-width: $sm) {
      padding: 0 144px;
    }

    @media (min-width: $md) {
      padding: 0 300px;
    }

    &-icon {
      margin-bottom: 20px;

      img {
        width: 87px;
        height: 20px;
      }
    }

    &-mark {
      margin-bottom: 34px;
      font-size: 20px;
      font-weight: $gbm-medium;
      line-height: 0.6;

      @media (min-width: $sm) {
        margin-bottom: 68px;
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 12px;
      font-size: 28px;

      @media (min-width: $sm) {
        width: 90%;
        margin-bottom: 24px;
        font-size: 48px;
      }

      @media (min-width: $md) {
        width: 80%;
      }

      @media (min-width: $xl) {
        width: 60%;
      }
    }

    &-message {
      margin-bottom: 40px;
      font-family: $font-stack-google;
      font-size: 16px;
      font-weight: $gbm-medium;
      line-height: 1.64;

      @media (min-width: $sm) {
        width: 90%;
        font-size: 20px;
      }

      @media (min-width: $md) {
        width: 80%;
      }

      @media (min-width: $xl) {
        width: 60%;
      }
    }
  }

  &.hidden {
    animation: slide 0.5s forwards;
    animation-delay: 0.1s;

    #{$class}__container {
      display: none;
    }
  }

  &__close {
    position: absolute;
    top: 21px;
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-between;

    img {
      margin-left: 32px;
    }

    &-button {
      z-index: 1;
      width: 28px;
      height: 28px;
      border: none;
      margin-right: 32px;
      background-color: transparent;
      background-image: url(../images/atm-cta-close.svg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      &--white {
        width: 16px;
        height: 16px;
      }

      &--black {
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(../images/atm-icn-close-black.svg);
      }
    }
  }

  // Overwrite guidelines v2
  .mark__wealth {
    .half-circle {
      :first-child {
        .fill {
          animation-delay: 1.5s;
        }
      }

      :last-child {
        .fill {
          animation-delay: 2s;
        }
      }
    }
  }
}
