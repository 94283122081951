.environment-alert {
  position: fixed;
  z-index: 1001;
  top: 16px;
  left: 50%;
  display: flex;
  width: 320px;
  max-width: 320px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $alert-blue;
  transform: translate(-160px);

  @include transition(0.25s);

  &:focus,
  &:hover {
    opacity: 0.5;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    background: url(../images/atm-test-enviroment-alert.svg) no-repeat 0 0;
  }

  &__message {
    color: $white;
    font-size: 14px;
    font-weight: $gbm-semibold;
  }
}
