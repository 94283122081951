.multiple-note {
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: #525252;
  font-size: 14px;

  img {
    margin-left: 8px;
  }
}

.options {
  display: grid;
  margin-top: 42px;
  grid-auto-rows: auto;
  grid-gap: 8px 32px;

  &--multiple {
    margin-top: 32px;
  }

  &--column-1 {
    grid-template-columns: repeat(1, 352px);
  }

  &--column-2 {
    grid-template-columns: repeat(2, 352px);

    @media (max-width: $sm) {
      grid-template-columns: repeat(1, 352px);
    }
  }

  &--column-3 {
    grid-template-columns: repeat(3, 160px);
  }

  .radio {
    &--bar {
      display: flex;
      width: auto;
      height: auto;
      margin-bottom: 8px;
      outline: 0;
    }
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }
}
