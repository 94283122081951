.advisor-information {
  display: inline-flex;
  max-width: 589px;
  min-height: 34px;
  border-radius: 4px;
  margin-bottom: 32px;
  background-color: rgba(68, 78, 230, 0.05);
  cursor: default;
  font-family: $font-stack-google;

  @media (max-width: $sm) {
    max-width: 460px;
    margin-top: 24px;
    margin-right: 16px;
    margin-bottom: 24px;
  }

  @media (max-width: $xs) {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    text-align: left;
  }

  strong {
    display: contents;
  }

  &--icon {
    width: 24px;
    margin-left: 24px;
    background: url("../images/atm-icn-bell-black.svg") no-repeat center transparent;
  }

  &--label {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    margin: 8px 0;
    color: $black;
    font-size: 14px;
    font-weight: $gbm-medium;
    line-height: 1.43;

    strong {
      font-weight: $gbm-bold;
    }

    @media (max-width: $xs) {
      font-size: 14px;
      line-height: 1.43;
    }
  }
}
