.simulator {
  display: flex;
  min-height: 100vh;

  @media (max-width: $sm) {
    padding: 0 24px;
  }

  &--dark {
    background-color: $simulator-bg;
    color: $white;
  }

  &--light {
    background-color: $white;
    color: $black;
  }

  &__container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: $xs) {
      display: table;
      margin-top: 112px;

      .btn-mobile {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        margin-bottom: 40px;
      }
    }
  }

  &__results {
    align-items: flex-start;
    padding-top: 80px;

    @media (max-width: $xs) {
      padding-top: 0;
      margin-bottom: 180px;
    }
  }

  &__content {
    width: 450px;

    @media (max-width: $sm) {
      width: 50%;
    }

    @media (max-width: $xs) {
      width: 100%;
    }

    &--stretch {
      padding-right: 50px;

      @media (max-width: $xs) {
        padding-right: 0;
      }
    }

    &--right {
      width: 470px;

      @media (max-width: $sm) {
        width: 50%;
      }

      @media (max-width: $xs) {
        width: 100%;
      }
    }

    & a {
      color: inherit;
    }

    &--chart {
      min-width: 493px;
      min-height: 382px;
      margin-left: -11px;

      @media (max-width: $xs) {
        width: 320px;
        min-width: 320px;
        margin-left: 0;
      }
    }
  }

  &__form {
    width: 352px;

    @media (max-width: $xs) {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    padding-top: 40px;
    margin-bottom: 64px;

    &:first-child {
      margin-bottom: 0;
    }

    @media (max-width: $xs) {
      display: grid;
      justify-content: center;
      padding-top: 80px;
      margin-bottom: 16px;

      &:nth-child(2) {
        display: flex;
        padding-top: 24px;
      }
    }

    .tooltip {
      width: fit-content;

      &__card {
        z-index: 1;
        width: auto;
        min-height: auto;
        box-shadow: none;
        font-size: 12px;
        white-space: nowrap;

        &--down {
          bottom: -45px;

          &:last-child {
            @media (max-width: $xs) {
              right: 0;
            }
          }
        }
      }

      &__indicator {
        border-bottom: none;
        color: $white;

        img {
          margin-left: 8px;
        }
      }
    }
  }

  &__btn-start {
    margin-right: 36px;

    @media (max-width: $xs) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__btn-invite,
  &__btn-send,
  &__btn-try {
    border: none;
    background-color: transparent;
    color: $white;
  }

  &__btn-test,
  &__btn-next,
  &__btn-again,
  &__btn-start {
    color: $black;
  }

  .product {
    &__title {
      margin-bottom: 64px;
    }

    &__description {
      margin-bottom: 48px;
    }

    &__disclaimer {
      margin-top: 38px;
      font-size: 10px;
      line-height: 15px;
    }
  }

  .button-icon {
    display: inline-flex;
    width: 48px;
    height: 48px;
    align-items: center;
    padding: 0;
    border: none;
    border-radius: 50%;
    margin: 0 24px 0 0;
    background-color: $gray900;
    color: $white;
    cursor: pointer;

    @media (max-width: $xs) {
      margin: 0 12px;
    }

    &--users {
      background-image: url('../images/icons/atm-icn-users.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;

      &:hover,
      &:focus {
        background-color: $white;
        background-image: url('../images/icons/atm-icn-users-black.svg');
      }
    }

    &--email {
      background-image: url('../images/icons/atm-icn-sm-mail.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;

      &:hover,
      &:focus {
        background-color: $white;
        background-image: url('../images/icons/atm-icn-sm-mail-black.svg');
        background-position: center;
        background-size: auto;
      }
    }

    &--share {
      background-image: url('../images/icons/atm-icn-sm-share.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;

      &:hover,
      &:focus {
        background-color: $white;
        background-image: url('../images/icons/atm-icn-sm-share-black.svg');
        background-position: center;
        background-size: auto;
      }
    }
  }

  .button {
    &__share {
      top: -130px;
      right: -180px;

      @media (max-width: $xs) {
        top: -192px;
        right: 0;
        left: -144px;
        margin: 0 auto;
      }
    }

    &__share-option {
      color: $black;
      text-decoration: none;

      &--messenger {
        &::before {
          background-image: url('../images/icons/atm-icn-sm-facebook.svg');
        }
      }
    }

    &--with-share {
      width: fit-content;
    }
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mb-60 {
    margin-bottom: 60px;
  }

  .mb-80 {
    margin-bottom: 80px;
  }

  .input {
    &-wrapper {
      &:first-child {
        margin-top: 0;
      }
    }

    &__label {
      margin-top: 16px;
    }

    &__input {
      background-color: transparent;
      color: $white;
      text-align: left;

      &--big {
        padding-bottom: 16px;
        padding-left: 0;
        font-size: 20px;

        &:focus {
          border-color: $white;
        }
      }
    }

    &--question {
      width: 352px;

      @media (max-width: $xs) {
        width: 100%;
      }
    }

    &--currency {
      .input__input {
        &--big {
          padding-left: 16px;
        }
      }
    }

    &--years,
    &--currency {
      width: 352px;

      @media (max-width: $xs) {
        width: 100%;
      }

      &::before,
      &::after {
        top: 4px;
        color: $white;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &--currency-usd::after {
      content: 'USD';
    }

    &--years-eng::after {
      content: 'YEARS';
    }

    &--error {
      & > :first-child {
        border-color: $single-input-error;
      }
    }

    &--error-label {
      color: $single-input-error;
      font-size: 12px;
    }
  }

  .checkbox {
    input[type='checkbox'].checkbox__input + label {
      &::before {
        border-color: $white;
        background: transparent;
      }
    }

    input[type='checkbox'].checkbox__input:checked + label::before {
      border-color: $white;
      background: url('../images/icons/atm-icn-check.svg') no-repeat 50%;
    }
  }
}
