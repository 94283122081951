.advisor-title {
  @include unselectable();

  h1 {
    width: 578px;
    margin-top: 40px;
    margin-bottom: 0;
  }

  &__subtitle {
    font-family: $font-stack-google;
    font-size: 20px;
    font-weight: $gbm-bold;
    line-height: 1.6;
  }
}
