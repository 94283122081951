.maintenance-mode {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: $black;
  background-image: url(../images/@2x-not-found-bg.png);
  background-position: center;
  background-size: cover;
  color: $white;
  font-size: 20px;
  font-weight: $gbm-medium;
  line-height: 1.5;

  .gbm-logo {
    width: 124px;
    height: 25px;
    margin-left: 354px;

    @media (max-width: $lg) {
      margin-left: 58px;
    }

    @media (max-width: $md) {
      margin-bottom: 124px;
      margin-left: 0;
    }

    @media (max-width: $xs) {
      margin-bottom: 96px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 156px;
    margin-left: 136px;

    @media (max-width: $xs) {
      overflow: hidden;
    }

    @media (max-width: $md) {
      margin-top: 104px;
      margin-left: 80px;
    }

    @media (max-width: $xs) {
      margin-top: 124px;
      margin-left: 17px;
    }

    &__header {
      display: inline-flex;

      @media (max-width: $md) {
        display: flex;
        flex-direction: column-reverse;
      }

      .title {
        max-width: 608px;
        margin-bottom: 24px;
        font-family: $font-stack-bold;
        font-size: 56px;
        line-height: 1.18;

        @media (max-width: $lg) {
          max-width: 554px;
          font-size: 48px;
          line-height: 1.21;
        }

        @media (max-width: $md) {
          max-width: 416px;
          margin-bottom: 16px;
        }

        @media (max-width: $xs) {
          max-width: 320px;
          margin-bottom: 16px;
          font-size: 32px;
        }
      }
    }

    &__subtitle {
      max-width: 608px;
      margin-bottom: 120px;
      font-size: 20px;
      line-height: 1.5;

      @media (max-width: $md) {
        max-width: 441px;
      }

      @media (max-width: $xs) {
        max-width: 320px;
        font-size: 14px;
      }
    }

    &__actions {
      margin-bottom: 57px;

      .blog-banner {
        max-width: 736px;

        @media (max-width: $md) {
          max-width: 608px;
        }

        @media (max-width: $xs) {
          max-width: 343px;

          &__header {
            .action {
              span {
                display: none;
              }
            }
          }

          &__body {
            overflow-y: scroll;
          }
        }
      }
    }
  }
}
