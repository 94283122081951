.slider {
  &__label {
    display: flex;
    width: 320px;
    justify-content: flex-end;
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: $gbm-bold;
  }
}

[data-reach-slider] {
  width: 320px;
  margin-bottom: 32px;
}

[data-reach-slider-track][data-orientation="horizontal"] {
  height: 4px;
}

[data-reach-slider-range] {
  background-color: $wm-blue;
}

[data-reach-slider-handle] {
  background-color: $wm-blue;
  outline: none;
}

[data-reach-slider-track] {
  background-color: $instruments-separator;
}
