.confirm-save {
  z-index: 5;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin-bottom: 24px;
  }

  &__description {
    width: 764px;
    margin-bottom: 48px;
    text-align: center;
  }

  &__image {
    width: 48px;
    height: 48px;
    margin-bottom: 10px;
  }
}
