/* stylelint-disable declaration-no-important */
@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700&display=swap");
@import '~modern-normalize/modern-normalize.css';
@import "~@gbm/guidelines-2.0/dist/guidelines.min.css";

@import "./mixins";
@import "./typography";
@import "./color-palette";
@import "./breakpoints";

// Components
@import "components/BackButton.scss";
@import "components/Bars.scss";
@import "components/Button.scss";
@import "components/DoneScreen.scss";
@import "components/Header.scss";
@import "components/HelpCard.scss";
@import "components/Input.scss";
@import "components/Loader.scss";
@import "components/Option.scss";
@import "components/Options.scss";
@import "components/PortfolioChart.scss";
@import "components/Sidebar.scss";
@import "components/Slider.scss";
@import "components/Title.scss";
@import "components/Tooltip.scss";
@import "components/Notification.scss";
@import "components/BlogBanner.scss";
@import "components/EnvironmentAlert.scss";
@import "components/Card.scss";
@import "components/InformationSection.scss";

// Views
@import "components/CancelScreen.scss";
@import "components/content.scss";
@import "components/form.scss";
@import "components/information.scss";
@import "components/Modal.scss";
@import "components/profile.scss";
@import "components/questionnaire.scss";
@import "components/portfolios.scss";
@import "components/results.scss";
@import "components/table.scss";
@import "components/WelcomeScreen.scss";
@import "components/MaintenanceModePage.scss";
@import "components/ConfirmSaveScreen.scss";
@import "components/PortfolioProperties.scss";

@import "containers/Simulator.scss";

body {
  margin: 0;
  font-family: $font-stack-google;
}

.positive {
  color: $wm-green;
}

.negative {
  color: $wm-red;
}

.questionnaire-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: 300px;
  overflow-x: hidden;

  @media (max-width: $md) {
    margin-left: 144px;
  }

  @media (max-width: $sm) {
    margin-left: 80px;
  }

  .column {
    width: auto;
  }

  @media (max-width: $sm) {
    display: flex;
    flex-direction: column;
  }
}

.advisor-title + .advisor-form {
  margin-top: 40px;
}

.advisor-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;

  @media (max-width: $sm) {
    margin-top: -24px;
  }

  &__chart-description:first-child {
    border-bottom: 1px solid $instruments-separator;
    padding-bottom: 13px;
  }

  &__controls {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;

    @media (max-width: $xs) {
      .advisor-button--primary {
        position: relative;
        top: 0;
        right: 0;
      }
    }

    .advisor-button {
      margin-top: 8px;
    }

    .checkbox {
      margin-bottom: 32px;
    }

    .link {
      outline: none;
    }

    &--fixed {
      position: fixed;
      bottom: 0;
      display: flex;
      width: 100%;
      height: 144px;
      justify-content: center;
      padding: 0;
      border-top: 1px solid $solitude-gray;
      background-color: $white;
      box-shadow: 0 0 2px 1px rgba(137, 147, 164, 0.3);
      opacity: 1;
      transition: all 0.2s ease-in;

      &-hidden {
        z-index: -1;
        opacity: 0;
      }

      .link {
        border: none;
        background-color: transparent;
        border-radius: 0;

        &:hover,
        &:focus {
          border: none;
        }
      }
    }
  }

  &__info,
  &--info {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding: 0 8px;

    @include border-box();

    @media (max-width: $sm) {
      width: 100%;
      flex-direction: row;
      align-items: center;
      padding: 0 24px 0 48px;
      overflow-x: hidden;
    }
  }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  &--chart {
    display: flex;
    flex-direction: column;
    vertical-align: text-top;

    > img {
      width: auto;
      max-width: 258px;
      height: auto;
      margin-top: 24px;
    }

    @include unselectable();
  }

  &--table {
    display: inline-block;
    max-width: 600px;
    vertical-align: text-top;

    @media (max-width: $sm) {
      margin-top: 80px;
      margin-left: 34px;

      &:last-child {
        border-bottom: none;
      }
    }

    @media (max-width: $md) {
      max-width: 352px;
    }
  }

  &--title {
    margin-bottom: 40px;
    color: $black;
    font-size: 14px;
    font-weight: $gbm-bold;
    line-height: 1.38;
    text-align: left;
  }

  &--description {
    margin-bottom: 16px;
    color: $black;
    font-size: 16px;
    font-weight: $gbm-medium;
    line-height: 1.5;
    text-align: justify;
  }

  &__profile {
    display: flex;
    width: 320px;
    flex-direction: column;

    &--old {
      display: flex;
      max-width: 320px;
      flex-direction: column;
    }

    @media (max-width: $md) {
      max-width: 320px;
    }

    &-title {
      margin-bottom: 24px;
    }

    &-bottom{
      margin-top: 24px;
    }

    &-bottom, &-title{
      color: $black;
      font-size: 14px;
      font-weight: $gbm-bold;
      line-height: 24px;
    }

    &-description {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: $gbm-medium;
      line-height: 1.57;

      @media (max-width: $sm) {
        padding-right: 32px;
      }
    }
  }
}

.chart-description {
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }
}

.advisor-results__disclaimer {
  display: none;
  max-width: 780px;
  margin: 0 auto;
  margin-top: 70px;
}

.advisor-results__sign,
.advisor-results__paragraph {
  display: inline-block;
  margin-bottom: 24px;
  font-size: 14px;
}

.advisor-results__sign {
  display: block;
  max-width: 220px;
  border-top: 1px solid $black;
  margin: 0 auto;
  margin-top: 80px;
}

.results-title {
  display: flex;
  justify-content: center;
}

.chart {
  display: flex;
  justify-content: center;
}

.logo-results {
  display: flex;
  height: 100px;
  margin-bottom: 48px;

  @media (max-width: $sm) {
    display: flex;
    height: 76px;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    margin-left: 0;

    + .close {
      top: 24px;
      right: 32px;
    }
  }

  @media (max-width: $xs) {
    margin-bottom: 24px;

    .advisor-header--logo {
      display: block;
    }

    + .close {
      top: 24px;
      right: 24px;
    }
  }

  .advisor-progress {
    position: absolute;
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
  }
}

.columns {
  display: flex;
  margin: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &.is-centered {
    @media (max-width: $xs) {
      justify-content: flex-start;
    }
  }
}
