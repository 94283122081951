/* stylelint-disable declaration-no-important */
.advisor {
  display: flex;
  height: 100vh;

  &-container {
    width: 100%;
    height: 100%;

    &__wrapper {
      height: 100%;
    }
  }
}

.question {
  margin-left: 16px;
}

.questionnaire {
  width: 100%;
  height: 100vh;

  &__close {
    display: flex;
    height: 76px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $xs) {
      position: absolute;
      top: 21px;
      width: 100%;
      height: auto;
    }

    img {
      margin-left: 32px;

      @media (max-width: $sm) {
        margin-left: 32px;
      }

      @media (max-width: $xs) {
        margin-left: 16px;
      }
    }

    &-button {
      position: absolute;
      z-index: 1;
      top: 24px;
      right: 10px;
      width: 28px;
      height: 28px;
      border: none;
      background-color: transparent;
      background-image: url(../images/atm-cta-close.svg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      &--white {
        @media (max-width: $xs) {
          width: 16px;
          height: 16px;
        }
      }

      &--black {
        display: none;

        @media (max-width: $xs) {
          display: block;
          width: 16px;
          height: 16px;
          background-image: url(../images/atm-icn-close-black.svg);
        }
      }
    }
  }

  &--question {
    position: relative;
    display: flex;
    max-width: 634px;
    align-items: center;
    margin-left: 16px;

    @media (max-width: $md) {
      margin-left: 64px;
    }

    @media (max-width: $sm) {
      position: unset;
      top: 60px;
      max-width: 544px;
      margin-bottom: 40px;
      margin-left: 176px;
    }

    @media (max-width: $sm) {
      top: unset;
      max-width: 544px;
      margin-bottom: 40px;
      margin-left: 112px;
    }

    @media (max-width: $xs) {
      display: block;
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &--options {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 16px;

    @media (max-width: $sm) {
      top: 0;
      margin-left: 176px;
    }

    @media (max-width: $sm) {
      top: unset;
      margin-left: 112px;
    }

    @media (max-width: $xs) {
      display: table;
      margin-left: 0;
    }

    &.portfolio-loss,
    &.win-lose,
    &.total-assets,
    &.experience-investing-fix,
    &.monthly-income {
      max-width: 320px;

      @media (max-width: $sm) {
        width: 544px;
        max-width: 544px;
        margin-left: 112px;

        .advisor-option {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-left: 0;
        }
      }

      @media (max-width: $xs) {
        width: 100%;
        margin-left: 0;

        .advisor-option {
          margin: 0;
        }
      }
    }

    &.fall-percentage-fix {
      .advisor-option-medium {
        min-width: 360px;
      }

      @media (max-width: $md) {
        .advisor-form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .advisor-option {
            width: 372px;
            min-width: 0;
            margin: 0;
          }
        }
      }

      @media (max-width: $sm) {
        .advisor-form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .advisor-option {
            width: 644px;
            min-width: 0;
            margin: 0;

            .radio-option--medium,
            .advisor-option--option-medium {
              &:nth-child(2n) {
                margin-right: 30px;
              }

              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }

      @media (max-width: $xs) {
        .advisor-form {
          .radio-option,
          .advisor-option {
            display: flex;
            width: auto;
            flex-flow: row wrap;
            justify-content: space-between;

            .radio-option--medium,
            .advisor-option--option-medium {
              width: 122px;
              height: 48px;
              margin-top: 8px;
              margin-right: 0;
              margin-bottom: 8px;

              &:nth-child(odd) {
                margin-left: 0;
              }

              .advisor-option--bullet {
                top: 14px;
                left: 16px;
              }
            }
          }
        }
      }
    }
  }

  &--subtitle {
    display: block;
    max-width: 592px;
    margin: 0 80px 0 0;
    color: $black;
    font-family: $font-stack;
    font-size: 16px;
    font-weight: $gbm-semibold;
    line-height: 1.5;
    text-align: left;

    @media (max-width: $sm) {
      max-width: 460px;
      margin: 16px 16px 32px 0;
      text-align: justify;
    }

    @media (max-width: $xs) {
      margin: 0 24px 24px;
      font-size: 14px;
      font-weight: $gbm-medium;
    }
  }

  .advisor-input-currency {
    max-width: 370px;
  }

  .advisor-input-years {
    max-width: 240px;
  }

  .welcome-fix {
    max-width: 665px;
    margin: 20px auto;
  }

  .initial-amount-fix {
    height: fit-content;

    .advisor-title {
      &--title {
        max-width: 670px;
      }

      @media (max-width: $sm) {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &--title {
          width: fit-content;
        }
      }
    }

    .questionnaire--subtitle {
      max-width: $xs;
    }
  }
}
