.help-card {
  display: flex;
  width: 544px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 32px;
  margin-bottom: 20px;

  &--info {
    background-color: $bg-help-card;
  }

  &__icon {
    width: 24px;
    height: 24px;
    padding-right: 16px;

    &--info {
      background: url("../images/atm-icn-bell.svg") no-repeat center center;
    }
  }

  &__message {
    margin-left: 16px;
    font-size: 14px;
    font-weight: $gbm-medium;
    line-height: 1.67;

    strong {
      font-weight: $gbm-bold;
    }
  }
}
