.bars {
  display: flex;
}

.bar {
  display: flex;
  width: 4px;
  height: 14px;
  margin-right: 6px;
  background-color: $clear-font;

  &:last-child {
    margin-right: 0;
  }

  &--active {
    background-color: $white;
  }
}
