.container-loader {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loader {
    margin-bottom: 16px;

    &__quote,
    &__custom-message {
      max-width: 400px;
      animation: fade-quote 1s ease-in-out;
      font-family: $font-stack-google;
      font-size: 16px;
      line-height: 1.75;
      opacity: 1;
      text-align: center;
      text-decoration: none;
    }

    &__custom-message {
      font-weight: $gbm-bold;
    }
  }

  &--dark {
    background-color: $simulator-bg;
  }

  @keyframes fade-quote {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
