/* stylelint-disable declaration-no-important */
.tooltip {
  position: relative;
  display: flex;
  align-items: center;

  &__indicator {
    display: flex;
    cursor: pointer;

    img {
      margin-left: 16px;
    }
  }

  &__card {
    position: absolute;
    display: block;
    width: 272px;
    min-height: 88px;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: $color-grey;
    box-shadow: 0 8px 20px 0 $bluey-grey-three;
    color: $white;
    font-family: $font-stack-google;
    font-weight: normal;
    line-height: 1.5;
    opacity: 0;
    transition: all 0.3s ease-out;
    visibility: hidden;

    &--right {
      top: -84px;
      left: 40px;
    }

    &--top {
      top: -168px;
      left: -96px;
    }

    &--bottom {
      bottom: -168px;
      left: -96px;
    }

    span {
      width: 240px;
      height: 72px;
      color: $charcoal;
      font-size: 12px;
      font-weight: $gbm-medium;
      line-height: 1.5;
    }
  }

  &:hover,
  &:focus {
    .tooltip__card {
      opacity: 1;
      visibility: visible;
    }
  }
}
