.back-button {
  z-index: 1;
  width: 100%;
  margin-left: 8px;
  background-color: $white;

  button {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;
  }

  &__icon {
    width: 24px;
    height: 24px;
    background: url("../images/atm-link-icn-back.svg") no-repeat center center transparent;
  }

  &__label {
    color: $black;
    font-size: 16px;
    font-weight: $gbm-bold;
    line-height: 1;
  }
}
