header {
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 76px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  transition: all 0.3s ease;

  img {
    margin-left: 32px;
  }

  @media (max-width: $xs) {
    position: relative;
    max-height: 60px;
    margin: 0;
  }
}

.header {
  &__back {
    &--hidden {
      display: none;
    }
  }
}

.advisor-header {
  &--logo {
    width: 100px;
    margin-left: 32px;

    @include unselectable();

    @media (max-width: $sm) {
      position: absolute;
      left: 32px;
      width: 79px;
      margin-left: 0;
    }

    @media (max-width: $xs) {
      left: 24px;
      width: 48px;
    }
  }
}
