/* stylelint-disable declaration-no-important */
.portfolios {
  width: 100%;
  max-width: 1056px;

  &__header {
    width: 100%;
    max-width: 700px;
    margin: 0 auto 40px auto;
    text-align: center;
  }

  &__cards {
    display: flex;
    padding: 15px 0 15px 15px;
    margin-bottom: 40px;

    &--adjust {
      justify-content: space-between;
    }

    &--overflow {
      width: 100%;
      overflow-x: scroll;
      scroll-behavior: smooth;
    }
  }

  &__content {
    width: 100%;
    max-width: 700px;
    margin: 0 auto 40px 0;
  }
}

.portfolio-card {
  width: 250px !important;
  min-width: 250px;
  max-height: none !important;
  padding: 30px 20px !important;
  margin-right: 30px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15) !important;
  text-align: center;

  &--clickable {
    cursor: pointer;
  }

  &--selected {
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.15) !important;
  }

  &__image {
    display: flex;
    width: 100%;
    height: 200px;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px auto;
  }
}
