.advisor-profiles {
  padding-top: 40px;

  @include border-box();

  &--profile {
    min-width: $gbm-light;
    height: 100%;
    padding: 40px 16px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 5px 20px 0 rgba(180, 193, 203, 0.2);

    @include transition(box-shadow 120ms ease-in-out);
    @include border-box();

    &:hover,
    &:focus {
      box-shadow: 0 5px 24px 0 rgba(180, 193, 203, 0.5);
    }
  }

  &--title {
    color: $black;
    cursor: default;
    font-family: $font-stack;
    font-size: 14px;
    font-weight: $gbm-extrabold;
    letter-spacing: 0.5px;
    line-height: 1.3;
  }

  &--body {
    margin-top: 36px;
    color: $black;
    cursor: default;
    font-family: $font-stack;
    font-size: 14px;
    font-weight: $gbm-medium;
    letter-spacing: 0.3px;
    line-height: 1.57;
  }
}
