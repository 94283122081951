.advisor-button {
  @media (max-width: $sm) {
    margin-top: 48px;
    margin-right: auto;
    margin-left: 0;
  }

  @media (max-width: $xs) {
    margin-right: 0;
  }

  @include border-box();
  @include unselectable();

  &--secondary {
    background-color: $white;
    color: $black;
    outline: none;

    @include transition(background-color 120ms ease-in-out);

    &:hover,
    &:focus {
      background-color: lighten($bluey-grey, 60);
    }

    &-hidden {
      display: none;
    }
  }

  & + &--link {
    margin-top: 25px;
  }
}

.button {
  &--top {
    margin-top: 48px;
  }

  &--wrapper {
    margin-top: 48px;

    .advisor-button--secondary {
      display: none;
    }

    @media (max-width: $xs) {
      display: flex;
      justify-content: space-between;
      margin: 40px 24px 40px 24px;

      .advisor-button--secondary {
        display: block;
        margin-top: 8px;
      }

      &-single {
        justify-content: flex-start;
        margin-right: 24px;

        .advisor-button--secondary {
          display: none;
        }
      }
    }
  }

  &--link {
    border: transparent;

    &.link {
      border-bottom: 1px solid transparent;
      background-color: transparent;
      font-size: 16px;

      &-old {
        margin-bottom: 80px;
      }
    }
  }
}
