@mixin border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin transition($prop) {
  -webkit-transition: $prop;
  -moz-transition: $prop;
  -o-transition: $prop;
  transition: $prop;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($value) {
  filter: alpha(opacity=$value);
  -moz-opacity: $value;
  opacity: $value;
}

@mixin box-shadow($prop) {
  -webkit-box-shadow: $prop;
  -moz-box-shadow: $prop;
  -o-box-shadow: $prop;
  box-shadow: $prop;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin circled {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin for-size($size) {
  @if $size == xs {
    @media (max-width: $xs) {
      @content;
    }
  }

  @if $size == sm {
    @media (max-width: $sm) {
      @content;
    }
  }

  @if $size == md {
    @media (max-width: $md) {
      @content;
    }
  }

  @if $size == lg {
    @media (max-width: $lg) {
      @content;
    }
  }

  @if $size == xl {
    @media (max-width: $xl) {
      @content;
    }
  }
}
