.blog-banner {
  display: flex;
  flex-direction: column;

  &__header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 1.63;

    .title {
      font-family: $font-stack-bold;
    }

    .action {
      display: inline-flex;
      align-items: center;
      color: $black;
      cursor: pointer;
      outline: none;
      text-decoration: none;

      .arrow {
        margin-left: 8px;
        transition: all 0.2s ease;
      }

      &:hover,
      &:focus {
        .arrow {
          margin-right: -8px;
          margin-left: 16px;
        }
      }

      &--light {
        color: $white;
      }
    }
  }

  &__body {
    display: inline-flex;

    .item {
      max-width: 224px;
      margin-right: 32px;
      color: $black;
      outline: none;
      text-decoration: none;

      @media screen and (max-width: $md) {
        max-width: 186px;
        margin-right: 24px;
      }

      &:last-child {
        margin-right: 0;
      }

      &__image {
        display: flex;
        overflow: hidden;
        height: 200px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-bottom: 24px;

        @media screen and (max-width: $md) {
          height: 166px;
        }

        .thumbnail {
          transition: transform 0.5s ease;
        }

        &:hover,
        &:focus {
          .thumbnail {
            transform: scale(1.2);
          }
        }
      }

      &__description {
        div {
          &:first-child {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: $gbm-bold;
            line-height: 1.86;

            @media screen and (max-width: $md) {
              line-height: 1.57;
            }
          }

          &:last-child {
            padding-right: 8px;
            font-family: $font-stack;
            font-size: 16px;
            line-height: 1.63;

            @media screen and (max-width: $md) {
              padding: 0;
              font-size: 14px;
              line-height: 1.57;
            }
          }
        }

        &--light {
          color: $white;
        }
      }
    }
  }
}
