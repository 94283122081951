.card {
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: $white;
  color: $clear-font;

  &--squared {
    &-right {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &-left {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    &-all {
      border-radius: 0;
    }
  }
}
