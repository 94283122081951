/* stylelint-disable declaration-no-important */
.done-screen {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: $white;
  color: $black;
  font-family: $font-stack;

  &__container {
    display: flex;
    width: 100%;
    max-width: 672px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -76px;
    margin-left: 300px;

    @media (max-width: $md) {
      align-items: initial;
      margin-left: 144px;
    }

    @media (max-width: $sm) {
      max-width: 480px;
      align-items: initial;
      margin-left: 80px;
    }

    &-icon {
      margin-bottom: 40px;

      @media (max-width: $xs) {
        margin-left: 32px;
      }

      img {
        width: 87px;
        height: 20px;
      }
    }

    &-mark {
      margin-bottom: 64px;
      font-size: 20px;
      font-weight: $gbm-medium;
      line-height: 0.6;

      @media (max-width: $xs) {
        margin-left: 32px;
      }
    }

    &__logo {
      width: 100px;
      padding-bottom: 96px;

      img {
        height: 20px;

        @media (max-width: $xs) {
          width: 140px;
          height: auto;
        }
      }

      @media (max-width: $sm) {
        width: 90%;
      }

      @media (max-width: $xs) {
        padding-bottom: 64px;
      }
    }

    h1 {
      margin-top: 0;
      margin-bottom: 24px;

      @media (max-width: $sm) {
        width: 90%;
      }

      @media (max-width: $xs) {
        font-size: 28px;
      }
    }

    &-message {
      padding-bottom: 40px;
      font-family: $font-stack-google;
      font-size: 20px;
      font-weight: $gbm-medium;
      line-height: 1.6;

      @media (max-width: $sm) {
        width: 90%;

        br {
          display: none;
        }
      }

      @media (max-width: $xs) {
        font-size: 16px;
        line-height: 1.64;
      }
    }

    &-controls {
      @media (max-width: $sm) {
        width: 90%;
      }

      button {
        width: 192px;
      }
    }
  }

  &__close {
    display: flex;
    height: 76px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $xs) {
      position: absolute;
      top: 21px;
      width: 100%;
      height: auto;
    }

    img {
      margin-left: 32px;

      @media (max-width: $sm) {
        margin-left: 32px;
      }

      @media (max-width: $xs) {
        margin-left: 16px;
      }
    }

    &-button {
      z-index: 1;
      width: 28px;
      height: 28px;
      border: none;
      margin-right: 10px;
      background-color: transparent;
      background-image: url(../images/atm-cta-close.svg);
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      &--white {
        @media (max-width: $xs) {
          width: 16px;
          height: 16px;
        }
      }

      &--black {
        display: none;

        @media (max-width: $xs) {
          display: block;
          width: 16px;
          height: 16px;
          background-image: url(../images/atm-icn-close-black.svg);
        }
      }
    }
  }
}
