// Color palette
$white: #FFF;
$black: #000;
$bright-blue: #026AFA;
$bright-blue-two: #007DFF;
$color-gray: #ADB7BF;
$color-gray-dark: #797979;
$color-gray-light: #EFEFEF;
$color-red: #E76262;
$bluey-grey: #8993A4;
$pale-grey-ten: #F0F6FF;
$aqua-haze: #F4F6F9;
$abbey: #575C60;
$cod-gray: #121212;
$pickled-bluewood: #2A324B;
$color-alert-success: #26DB5A;
$solitude-gray: #E1E7F0;
$charcoal: #0D0E0E;
$bluey-grey-three: #ADB7C4;
$bg-help-card: #F0F1FE;

// Rebranding
$wm-blue: #444EE6;
$wm-green: #24A148;
$wm-red: #DA1E28;
$single-input-grey: #6F6F6F;
$single-input-border: #A8A8A8;
$single-input-error: #E96B70;
$instruments-separator: #E0E0E0;
$color-grey: #393939;
$equity: #1C12B1;
$clear-font: #525252;
$disclaimer-box: #CBCBFA;

// Light Theme/Background Core/backgroundSecondary
$background-secondary: #F3F3F3;

// Environment Alert
$alert-blue: #6934FF;

// Simulator
$simulator-bg: #141414;
$gray900: #262626;
