.information-section {
  display: flex;
  width: 192px;
  flex-direction: column;

  img {
    width: 21.5px;
    margin-right: 11px;
  }

  &__description {
    display: flex;
    align-items: flex-start;
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-wrap: nowrap;

    &--bold-description {
      margin-bottom: 10px;
      font-weight: $gbm-bold;
    }
  }

  &__value {
    color: $wm-blue;
    font-family: $font-stack;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 34px;
  }

  &__subtitle {
    color: $single-input-grey;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
  }
}
