/* stylelint-disable declaration-no-important */
.notification {
  position: fixed !important;
  z-index: 5;
  top: 24px;
  right: 0;
  left: 0;
  display: none !important;
  width: 800px;
  width: 608px;
  align-items: center;
  margin: 0 auto;
  animation: fade-in 1s forwards;
  opacity: 0;
  outline: none;

  @include for-size(xs) {
    width: 320px;
  }

  &__close {
    appearance: none;
    outline: none;
  }

  &--active {
    display: flex !important;
    animation: fade-in 1s forwards;
  }

  &--closing {
    animation: fade-out 0.3s forwards;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
